// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.itemListApiContentType = "application/json";
exports.urlGetItemList = "bx_block_order_reservations/reservation_services";
exports.urlGetEarningsList =
  "bx_block_order_reservations/reservation_services/earnings";
exports.urlGetReservationList = "reservations/list";
exports.urlGetEventsList = "bx_block_order_reservations/events/list";
exports.urlGetBlockdateList = "bx_block_calendar/booked_slots";
exports.urlGetEventDetailByDate =
  "bx_block_calendar/booked_slots/reserve_docks";
exports.urlPostUpdateBasePrice =
  "bx_block_content_management/dock_listings";
exports.itemListApiMethodType = "GET";
exports.urlPostDateOption = "bx_block_content_management/dock_availabilities";

exports.urlSecureReservationList = "bx_block_order_reservations/account_orders";
exports.secureReservationApiMethodType = "POST";

exports.urlGetReservationHistory = "bx_block_order_reservations/account_orders";
exports.getReservationHistoryApiMethodType = "GET";

exports.urlEditReservation = "bx_block_order_reservations/account_orders/";
exports.editReservationApiMethodType = "PUT";

exports.urlCancelReservation = "bx_block_order_reservations/account_orders/";
exports.cancelReservationApiMethodType = "DELETE";

exports.urlGetDockListingDetails = "bx_block_content_management/dock_listings";
exports.getDockListingDetailsApiMethodType = "GET";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Reservations";
exports.labelBodyText = "Reservations Services List";
exports.txtInputPlaceholder = "quantity";
exports.totalPrice = "Total Price";
exports.edit = "Edit";
exports.delete = "Cancel";
exports.reservationStatus = "Reservation Status";

exports.btnExampleTitle = "Secure Reservation";
exports.btnEditTitle = "Edit";
exports.btnCancelTitle = "Cancel";
exports.btnHistoryExampleTitle = "Go To Reservation History";
exports.textquantityplaceholder = "quantity";

exports.txtOrderId = "Order id";
exports.orderPlacedDate = "Order Date";
exports.sortBy = "Sort By";
exports.search = "Search";
exports.accountsAPiEndPoint = "bx_block_login/logins";

exports.headerText = "Does your dock have lonely days?";
exports.bodyText =
  "Share your dock with others for the day, week or month! It’s easy, free and brings in cash!";

exports.hostingOnDayDockerText = "Hosting on Daydocker is easy";
exports.listYourDockTitle = "List your dock for free";
exports.acceptReservationsTitle = "Accept reservations ";
exports.getPaidTitle = "Get paid";
exports.listYourDockDescription =
  "Upload pictures, set a rate, and describe your dock and any dock rules.";
exports.acceptReservationsDescription =
  "Allow instant reservations or review and approve reservation requests from boaters. ";
exports.getPaidDescription =
  "Secure payments are delivered directly to your bank account pomptly after a completed reservation.";
exports.btnListYourDockText = "List Your Dock";

exports.protectionGuaranteeTitle =
  "Upto $2,000,000 protection guarantee provided";
exports.protectionGuaranteeDescription =
  "Our Daydocker Protection Guarantee protects all bookings done via Daydocker for up to $2million for general liability claims and $10,000 of property protection per occurrence.";

exports.learneMoreTitle = "Learn more about Hosting";
exports.learnMoreDescription =
  "Visit FAQ to learn more.If you have any questions along the way, our amazing customer support team is here to help you.";
exports.btnFAQsText = "FAQs";
exports.btnContactUsText = "Contact Us";
exports.reservationsTitleText = "Reservations";
exports.myReservationsTitleText = "My Reservations";
exports.dockRequestsText = "Dock requests";
exports.boaterDockingText = "Where you’re docking";
exports.boaterDockedText = "Where you’ve docked";
exports.dockedText = "Past reservations";
exports.noUpcomingReservationsText = "You have no upcoming reservations";
exports.noReservationsText = "You have no reservations";
exports.goToListings = "Go to your listings";
exports.exploreDaydocker = "Explore Daydocker";
exports.chatPath = "bx_block_chat/chats?type=host";
exports.earningsText = "Earnings";
exports.noEarningsText = "You have no earnings";
exports.calendarTitleText = "Calendar";
exports.pricingText = "Pricing";
exports.wantToMakeMoneyText = "Want to make more money?";
exports.customPriceButtonText = "Custom weekend price";
exports.availabityText = "Availability";
exports.availabityHelperText = "Select a date to mark as block/available";
exports.blockDateHelperText = "Do you want to block this date on calendar?";
exports.availableText = "Available";
exports.blockText = "Block";
exports.saveBtnText = "Save";

exports.manageListingTitleText = "Manage Listing";
exports.addListingBtn = "Add new listing";
exports.searchPlaceholder = "Search for names, listings here";
exports.dayPriceLabel = "DayPrice";
exports.yourEarningsLabel = "Your earnings";
exports.daydockerGuestTotalLabel = "Daydocker guest total";
exports.totalPayLabel = "Total pay";
exports.noListingsFoundText = "You have no listings to manage";

exports.searchDockPath = "bx_block_advanced_search/dock_search";
exports.statusLabelText = "UNAVAILABLE";
exports.menuUpdateStatusText1 = "Set as unavailable";
exports.menuUpdateStatusText2 = "Set as available";
exports.featureText = "Features";
exports.addOnsText = "Add-ons";
exports.rulesAndInstructionText = "Rules and instructions";
exports.editTextBtn = "Edit";
exports.cancellationPolicyText = "Cancellation Policy";
exports.manageListingTitleText = "Manage Listing";
exports.hostedByText = "Hosted by";
exports.dayText = "/ day";
exports.dayDockerText = "Daydocker";
exports.longerDockerText = "Longerdocker";
exports.checkinText = "CHECK-IN";
exports.checkoutText = "CHECKOUT";
exports.guestsText = "GUESTS";
exports.requestReservationBtnText = "Request Reservation";
exports.instantBookBtnText = "Instant Book";
exports.reportListingText = "Report this listing";
exports.wontBeChargedText = "You can cancel anytime before 28 May 2024.";
exports.dockTypeText = "Dock type";
exports.boatDockingAreaText = "Boat docking area";
exports.waterDepthText = "Water depth";
exports.dockPricesText = "Dock prices";
exports.perDayText = "per day";
exports.perWeekText = "per week";
exports.perMonthText = "per month";
exports.dockAvailabilityText = "Dock Availability";
exports.whyThisDockText = "Why this dock?";
exports.showMoreText = "Show more";
exports.showLessText = "Show less";
exports.showAllText = "Show all reviews";
exports.showLessReviews = "Show less reviews";
exports.thingsToKnowText = "Things to know";
exports.dockRulesText = "Dock rules";
exports.healthAndSafetyText = "Health & safety";
exports.reservationTypeBookNow = "Use book now";
exports.reservationTypeConfirmReservation = "Confirm each reservation request";
exports.dockBookingType = "Dock";
exports.reqeustReservationBookingType = "Request Reservation";
exports.instantBookingType = "Instant Book";
exports.reviewsPath = "/bx_block_reviews/reviews?dock_listing_id=";
exports.reviewsPostPath = "bx_block_reviews/reviews";
exports.messageTheHostText = "Message the Host";
exports.messageInfoText =
  "Share your plans for the dock, who’s coming with you and anything else you want to share.";
exports.messagePlaceholder = "Your message here..";
exports.messageAlertText =
  "Your reservation won’t be confirmed until the hoster approves your request within 24 hours. After approval, you will receive an email asking you to confirm and pay for your dock reservation.";
exports.requestBookingBtnText = "Request a booking";
exports.dateOfBookingText = "Date of Booking";
exports.dayByPriceText = "day x price";
exports.addonText = "Add-ons";
exports.amenitiesText = "Amenities";
exports.editText = "Edit";
exports.taxesText = "Taxes";
exports.afterTaxText = "(after taxes)";
exports.selectPaymentMethodBtnText = "Select a Payment Method";
exports.backBtnText = "Back";
exports.dockDetailsText = "Dock booking details";
exports.guestText = "Guests";
exports.notAddedText = "Not Added";
exports.paymentDetailsText = "Payment Details";
exports.savedCardText = "Your saved card";
exports.paywithText = "Pay with";
exports.bookDockBtnText = "Book dock";
exports.insuranceText = "Insurance";
exports.cancelBookingBtnText = "Cancel booking";
exports.nameofCardHolderText = "Name of the card holder";
exports.cardNumberText = "Card number";
exports.expiryDateText = "Expiry date";
exports.cvvText = "CVV";
exports.zipCodeText = "ZIP code";
exports.namePlaceholder = "e.g. Jassie Dane";
exports.cardPlaceholder = "XXXX XXXX XXXX XXXX";
exports.datePlaceholder = "MM/YYYY";
exports.cvvPlaceholder = "***";
exports.zipCodePlaceholder = "1234";
exports.deleteListingText = "Delete listing";
exports.deleteDialogTitle = "Delete Listing";
exports.deleteDialogText = "Do you want to delete this Listing?";
exports.deleteDialogBtn = "Delete";
exports.selectListingText = "Select listing";
exports.noListedDockText = "No listed dock available";
exports.selectText = "Select";
exports.confirmBtnText = "Confirm";
exports.basePayText = "Base Pay";
exports.basePriceText = "Base Price";
exports.customWeekendPriceText = "Custom Weekend Price";
exports.baseDayPriceText = "Base pay price";
exports.dayDockerServiceFee = "Daydocker service fee";
exports.beforeTaxes = "before taxes";
exports.yourEarnText = "Your earn";
exports.addedToFavBtnText = "Added to favorites";
exports.addToFavBtnText = "Add to favorites";
exports.cancelBookingConfirmText = "Are you sure you want to cancel booking?";
exports.noBtnText = "No";
exports.yesBtnText = "Yes";
exports.reviewDockText = "Review Dock";
exports.selectedDockText = "Selected Listing";
exports.verificationInProgressText = "Verification is in progress";
exports.verifyInfo = "It may take some time to verify your account";
exports.seeDetailsInfo = "See your booking details below...";
exports.noAddonText = "No Add-ons";
exports.noFeaturesText = "No Listing Features";
// Customizable Area End
