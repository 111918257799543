import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  Box,
  styled,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Button,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import Header from "../../../components/src/Header";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DockBookingPayment from "./DockBookingPayment.web";
import { BookingPage } from "./DockBookingController.web";
import Toast from "../../../components/src/Toast";
import GenericModal from "../../../components/src/GenericModal";
import Login from "../../../blocks/email-account-login/src/Login.web";

const configJSON = require("./config.js");
const assets = require("./assets");

const url = new URL(document.baseURI);
const booking = url.searchParams.get("booking");

const modalTheme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
    },
    secondary: {
      main: "#059669",
    },
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: 700,
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        color: "#0F172A",
      },
      body1: {
        fontSize: "1rem",
        fontFamily: "Outfit",
        fontWeight: 700,
      },
      body2: {
        fontSize: "0.9rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#0F172A",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "8px",
      },
      adornedEnd: {
        paddingRight: 0,
        borderRadius: "8px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        fontFamily: "Outfit",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "capitalize",
      },
      containedPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#0F172A",
        opacity: "0.4 !important",
      },
    },
    MuiDialogContent: {
      root: {
        boxSizing: "border-box",
        padding: "32px 32px",
        "&:first-child": {
          paddingTop: 34,
        },
        background: "#FFF",
        boxShadow:
          "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
        borderRadius: "32px",
      },
    },
    MuiPaper: {
      root: {
        borderRadius: "32px !important",
      },
    },
  },
});

const renderAddons = (addons: {
  name: string;
  price: number;
}[]) => {
  return addons?.length ? (
    addons.map((addon) => (
      <Typography
        key={addon.name}
        variant="body1"
        className="marginTop10"
      >
        {addon.name}
      </Typography>
    ))
  ) : (
    <Typography
      data-test-id="notAddedText"
      variant="body1"
      className="marginTop10"
    >
      {configJSON.notAddedText}
    </Typography>
  );
};
// Customizable Area End

import DockBookingPreviewController, {
  Props,
} from "./DockBookingPreviewController.web";

export default class DockBookingPreview extends DockBookingPreviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      bookingPage,
      selectedDetails,
      dockDetails,
      onBackBtnClick,
      navigation,
      kycModal,
    } = this.props;
    const { addons, checkin_date, checkout_date, guests } = selectedDetails;

    return (
      <>
        <Login
          data-test-id="loginModal"
          isOpen={this.state.loginModal}
          onClose={() => {
            this.setState({ loginModal: false });
          }}
          navigation={navigation}
          id="login modal"
        />
        <GenericModal
          data-test-id="confirmationModal"
          open={this.state.isCancelled}
          isCloseIcon={false}
          onBack={this.handleCancelModal}
        >
          <ThemeProvider theme={modalTheme}>
            <Wrapper>
              {this.state.confirmationError && (
                <Toast
                  message={this.state.confirmationError}
                  key={new Date().valueOf()}
                  type="error"
                />
              )}
              <Typography data-test-id="confirmationModalTitle" variant="h6">
                {configJSON.cancelBookingBtnText}?
              </Typography>
              <Typography variant="body2" className="colorGrey">
                {configJSON.cancelBookingConfirmText}
              </Typography>
              <Typography
                data-test-id="cancelConfirmationBtn"
                variant="body1"
                color="primary"
                style={{
                  margin: "25px 0 10px 0",
                  padding: "5px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={this.handleCancelModal}
              >
                {configJSON.noBtnText}
              </Typography>
              <Button
                data-test-id="yesConfirmationBtn"
                variant="contained"
                color="primary"
                style={{
                  width: "100%",
                  padding: "10px",
                }}
                onClick={this.cancelDockBooking}
              >
                {configJSON.yesBtnText}, {configJSON.cancelBookingBtnText}
              </Button>
            </Wrapper>
          </ThemeProvider>
        </GenericModal>
        {bookingPage === BookingPage.Details ||
        bookingPage === BookingPage.Booked ? (
          <Wrapper>
            <Header navigation={navigation} />
            {bookingPage !== BookingPage.Booked && (
              <Button
                className="backBtn"
                startIcon={<ArrowBackIosRoundedIcon />}
                onClick={onBackBtnClick}
                data-test-id="backBtn"
              >
                {configJSON.backBtnText}
              </Button>
            )}
            {this.booking === "pending" && <Box className="verificationBox">
              <Typography variant="h5" className="fontWeight700">
                {configJSON.verificationInProgressText}
              </Typography>
              <img src={assets.timerGif} width={70} />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle1" className="colorGrey">
                  {configJSON.verifyInfo}
                </Typography>
                <Typography variant="subtitle1" className="colorGrey">
                  {configJSON.seeDetailsInfo}
                </Typography>
              </Box>
            </Box>}
            <Box className="flexItems justifySpaceBetween">
              <Box>
                <Typography
                  variant="h1"
                  className="fontWeight700 headerTitle"
                  data-test-id="titleText"
                >
                  {configJSON.dockDetailsText}
                </Typography>
                {bookingPage === BookingPage.Booked && (
                  <Typography
                    data-test-id="sucessMsgTitle"
                    className="sucessMsgTitle"
                  >
                    {this.state.detailsText}
                  </Typography>
                )}
              </Box>
              {this.renderButtons()}
              {bookingPage === BookingPage.Details && (
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "rgb(221, 221, 90)",
                  }}
                >
                  <img
                    src={assets.timeIcon}
                    style={{ width: "25px", height: "auto" }}
                    loading="lazy"
                  />
                  {this.props.timer}
                </Typography>
              )}
            </Box>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <Box>
                  <Typography variant="h5" className="fontWeight700">
                    {dockDetails.listing_title}
                  </Typography>
                  <Typography variant="body1" className="paddingTop5">
                    {dockDetails.about}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="ratingText fontWeight700"
                  >
                    <StarBorderRoundedIcon className="ratingIcon" />
                    {(dockDetails.host.rating || 0).toFixed(1)}
                    <FiberManualRecordIcon
                      style={{
                        fontSize: "4px",
                        color: "#6B7280",
                        padding: "0 5px 0 5px",
                      }}
                    />
                    <Typography variant="body1" className="fontWeight700">
                      {dockDetails.host.reviews} reviews
                    </Typography>
                  </Typography>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={dockDetails.host.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={configJSON.hostedByText}
                      primary={dockDetails.host.name}
                    />
                  </ListItem>
                </Box>
                <Divider />
                <Box>
                  <Typography variant="body1" className="fontWeight700">
                    {configJSON.dateOfBookingText}
                  </Typography>
                  <Typography variant="body1" className="marginTop10">
                    {checkin_date.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}{" "}
                    -{" "}
                    {checkout_date.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    className="marginTop20 fontWeight700"
                  >
                    {configJSON.guestText}
                  </Typography>
                  <Typography variant="body1" className="marginTop10">
                    {guests} guests
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    className="marginTop20 fontWeight700"
                  >
                    {configJSON.addOnsText}
                  </Typography>
                  {renderAddons(addons)}
                </Box>
                <Divider />
                {this.state.errorMsg && bookingPage === BookingPage.Booked && (
                  <Toast message={this.state.errorMsg} />
                )}
                <Box className="flexItems justifySpaceBetween">
                  <Box>
                    <Typography
                      variant="body1"
                      className="fontWeight700 subHeader"
                    >
                      {configJSON.cancellationPolicyText}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="marginTop10 colorGrey"
                    >
                      {this.state.cancellationPolicy}
                    </Typography>
                  </Box>
                  {bookingPage === BookingPage.Booked &&
                    !this.state.isSuccessfullyCancelled && (
                      <Button
                        data-test-id="cancelBookingBtn"
                        variant="outlined"
                        color="secondary"
                        onClick={this.handleCancelModal}
                      >
                        {configJSON.cancelBookingBtnText}
                      </Button>
                    )}
                </Box>
                <Divider />
                {!this.state.isSuccessfullyCancelled && (
                  <>
                    <Typography variant="body1" className="fontWeight700 subHeader">
                      {configJSON.reviewDockText}
                    </Typography>
                    <Box>
                      <ListItem style={{marginTop:'22px', marginBottom:'16px'}}>
                        <ListItemAvatar>
                          {this.getAvatar()}
                        </ListItemAvatar>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="body1" className="fontWeight600">
                            {dockDetails.host.name}
                          </Typography>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>                        
                            {this.state.noOfStar.map((star) => (
                              <img
                                key={star - 1}
                                src={this.getStarImageSrc(star - 1)}
                                alt="review"
                                style={{ 
                                  cursor: "pointer",
                                  pointerEvents: this.props.bookingPage === BookingPage.Booked ? "none" : "initial"
                                }}
                                loading="lazy"
                                onClick={() => this.handleStarClick(star - 1)}
                              />
                            ))}
                          </div>
                        </div>
                      </ListItem>  
                      <form >
                        <textarea
                          name="description"
                          placeholder="Let others know your experience at the dock."
                          maxLength={400}
                          className="textArea"
                          style={{resize: 'none'}}
                          value={this.state.description}
                          data-test-id='Description'
                          onChange={(event) => this.handleDescription(event)}
                          disabled={this.props.bookingPage === BookingPage.Booked}
                        />
                      </form>  
                    </Box>          
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                {this.state.errorMsg && bookingPage !== BookingPage.Booked && (
                  <Toast message={this.state.errorMsg} />
                )}
                {this.renderPaymentDetailCard()}
              </Grid>
            </Grid>
          </Wrapper>
        ) : (
          <DockBookingPayment
            paymentIntentId={this.state.paymentIntentId}
            onBackBtnClick={onBackBtnClick}
            renderPaymentCard={this.renderPaymentDetailCard}
            navigation={navigation}
            onSuccessCall={this.props.onSuccessCall}
            timer={this.props.timer}
            kycModal={kycModal}
            reservationId={dockDetails.id}
            id="DockBookingPayment"
          />
        )}
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")({
  "& .textArea": {
    width: '100%',  minHeight: '98px', border: '1px solid #CBD5E1', color: "#64748B", fontWeight: 400,
    borderRadius: '8px',
    padding: '12px 8px',fontSize:"16px",fontFamily: "Outfit",overflow: 'hidden', 
  },
  "& .fontWeight700": {
    fontWeight: "700 !important",
  },
  "& .fontWeight600": {
    fontWeight: "600 !important",
  },
  "& .paddingTop5": {
    paddingTop: "5px",
  },
  "& .marginTop0": {
    marginTop: 0,
  },
  "& .marginTop20": {
    marginTop: "20px",
  },
  "& .marginBottom10": {
    margin: "15px 0 10px",
  },
  "& .flexItems": {
    display: "flex",
    gap: 10,
  },
  "& .infoBtn": {
    marginTop: 2,
  },
  "& .justifySpaceBetween": {
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .colorGrey": {
    color: "#64748B",
  },
  "& .colorDarkBlue": {
    color: "#0F172A",
  },
  "& .marginTop10": {
    marginTop: "10px",
  },
  "& .flexGap": {
    display: "flex",
    gap: "15px",
    flexDirection: "column",
  },
  "& .guestTotalFlex": {
    display: "flex",
    flexDirection: "column",
  },
  "& .headerTitle": {
    margin: "15px 0 20px 0",
  },
  "& .sucessMsgTitle": {
    margin: "-10px 0 15px 0",
    fontFamily: "Josefin Sans !important",
    fontSize: "24px !important",
    fontWeight: 400,
  },
  "& .backBtn": {
    fontSize: "18px",
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#64748B",
    textTransform: "none",
  },
  "& .borderedBox": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "30px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  "& .ratingText": {
    display: "flex",
    gap: 0,
    padding: "15px 0",
    alignItems: "center",
  },
  "& .ratingIcon": {
    color: "#DE3151",
  },
  "& .subHeader": {
    fontSize: "1.07rem !important",
  },
  "& .verificationBox": {
    display: "flex",
    gap: 5,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0 70px 0",
  },
});
// Customizable Area End
