import React from "react";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { v4 as uuidv4 } from "uuid";
import { runEngine } from "../../framework/src/RunEngine";

interface Props {
  name: string;
  image: string;
  message: string;
  redirectedMsg?: string;
  redirectionRoute?: string;
  navigation?: any;
}

const theme = createTheme({
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
      },
      h6: {
        fontSize: "1rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        color: "#4F9FF8",
      },
    },
  },
});

const Wrapper = styled("div")(({ theme }) => ({
  "& .pointer": {
    cursor: "pointer",
  },
  "& .notFoundMain": {
    padding: "80px 0 80px 0",
    display: "flex",
    gap: "15px",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  "& .notFoundMsg": {
    paddingTop: 20,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
  },
  "& .notFoundImg": {
    height: "35%",
    width: "35%",
    objectFit: "contain" as "contain",
  },
  [theme.breakpoints.down(840)]: {
    "& .headerTitle": {
      fontSize: "1.3rem",
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .headerTitle": {
      fontSize: "1.2rem",
    },
    "& .notFoundImg": {
      width: "65%",
    },
  },
}));

export default class NoDataFound extends React.Component<Props> {
  send: (message: Message) => void;
  constructor(props: any) {
    super(props);
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
  }

  handleRedirect = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      this.props.redirectionRoute
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Box className="notFoundMain">
            <img
              src={this.props.image}
              alt={this.props.name}
              loading="lazy"
              className="notFoundImg"
            />
            <Box className="notFoundMsg">
              <Typography variant="h4" className="headerTitle">
                {this.props.message}
              </Typography>
              {this.props.redirectedMsg && (
                <Typography
                  variant="h6"
                  className="pointer"
                  onClick={this.handleRedirect}
                >
                  {this.props.redirectedMsg}
                </Typography>
              )}
            </Box>
          </Box>
        </Wrapper>
      </ThemeProvider>
    );
  }
}
