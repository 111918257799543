import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
const assets = require("./assets");
import {
  Avatar,
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "react-datepicker/dist/react-datepicker.css";
import {
  getLastPartOfURL,
  sendAPIRequest,
} from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import CustomInput from "../../../components/src/CustomInput.web";
import Toast from "../../../components/src/Toast";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import AddPhotoAlternateRoundedIcon from "@material-ui/icons/AddPhotoAlternateRounded";
import { format } from "date-fns";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}

export interface IChatRoom {
  id: number;
  roomId: number;
  last_message: string;
  name: string;
  avatar: string | null;
}

export interface ITab {
  value: string;
  label: string;
}

export interface IMessage {
  id: number;
  account_id: number;
  chat_id: number;
  created_at: string;
  updated_at: string;
  is_mark_read: boolean;
  message: string;
  messenger_type: string;
  avatar: string;
  attachments: { id: number; url: string }[] | null;
}

interface IGroupedMessages {
  [date: string]: IMessage[];
}

interface IChatReservationDetails {
  id: number;
  latitude: string;
  longitude: string;
  dock_type: string;
  listing_type: string;
  docking_length: number;
  docking_width: number;
  listing_title: string;
  about: string;
  access: string;
  parking_space: number;
  max_boats: number;
  max_guests: number;
  allow_pets: boolean;
  allow_smoking: boolean;
  allow_liveaboards: boolean;
  rules_and_cautions: string;
  has_security_camera: boolean;
  has_animals: boolean;
  reservation_type: string;
  base_price: string;
  cancellation_policy: string;
  completed: boolean;
  step: number;
  account_id: number;
  available: boolean;
  listed_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  address: string | null;
  city: string | null;
  locality: string | null;
  administrative_area_level_1: string | null;
  administrative_area_level_2: string | null;
  state_short_name: string | null;
  lake_id: number | null;
}

type IAttachmentPrevs = string | ArrayBuffer | null;
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  isChatOpen: boolean;
  openChatInfo: IChatRoom;
  chatList: IChat[];
  currentPage: number;
  chatRoomList: IChatRoom[];
  isVisibleModal: boolean;
  anchorElMenu: null | HTMLElement;
  selectedChatRoom: number;
  chatReservationDetails: IChatReservationDetails;
  messages: IMessage[];
  attachments: string[];
  attachment_prevs: IAttachmentPrevs[];
  newMessage: string;
  chatListError: string;
  openChatError: string;
  detailsError: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  userRole = getLastPartOfURL().role || "boater";
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  createMessageApiCallId: string = "";
  openChatApiCallId: string = "";
  showMessagesApiCallId: string = "";
  removeChatApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      currentPage: 1,
      isVisibleModal: false,
      anchorElMenu: null,
      selectedChatRoom: -1,
      isChatOpen: true,
      openChatInfo: { id: -1, roomId: -1, name: "", last_message: "", avatar: null },
      chatReservationDetails: {} as IChatReservationDetails,
      chatRoomList: [],
      messages: [],
      attachments: [],
      attachment_prevs: [null],
      newMessage: "",
      chatListError: "",
      openChatError: "",
      detailsError: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.getChatList();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (
      this.state.messages !== prevState.messages ||
      this.state.attachment_prevs !== prevState.attachment_prevs ||
      this.state.openChatInfo !== prevState.openChatInfo
    ) {
      const chatContainer = document.getElementById("chat-container");
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      this.setState({ isChatOpen: false });
    } else {
      this.setState({ isChatOpen: true });
    }
  };

  getToken = () => {};

  isStringNullOrBlank = (string: string) => {};

  navigateToChatView = (chatId: string) => {};

  openChat = (chatInfo: IChatRoom) => {
    this.setState({
      selectedChatRoom: chatInfo.roomId,
      openChatInfo: chatInfo,
      newMessage: "",
      attachment_prevs: [null],
    });
    this.showMessages();
    this.openChatMessage();
  };

  closeChat = () => {
    this.setState({
      openChatInfo: { id: -1, roomId: -1, name: "", last_message: "", avatar: null },
    });
  };

  createChatRoom = (chatName: string) => {};

  inputRoomNameProps = {};

  btnAddRoomProps = {};

  btnCloseModalProps = {};

  btnShowAddModalProps = {};

  handleChatNameChange = (chatName: string) => {};

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJson);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getChatListApiCallId]: this.handleAPIResponse,
      [this.createMessageApiCallId]: this.handleCreateMessageAPIResponse,
      [this.openChatApiCallId]: this.handleOpenChatAPIResponse,
      [this.showMessagesApiCallId]: this.handleMessagesAPIResponse,
      [this.removeChatApiCallId]: this.handleRemoveChatAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJson: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  getChatList = async () => {
    const token = await getStorageData("token");
    this.setState({ chatListError: "" });
    this.getChatListApiCallId = sendAPIRequest(
      `${configJSON.getMyChatsApiEndpoint}?type=${this.userRole}`,
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  openChatMessage = async () => {
    this.setState({ openChatError: "" });
    const token = await getStorageData("token");
    this.openChatApiCallId = sendAPIRequest(
      `${configJSON.getMyChatsApiEndpoint}/${this.state.selectedChatRoom}`,
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  showMessages = async () => {
    this.setState({ openChatError: "" });
    const token = await getStorageData("token");
    this.showMessagesApiCallId = sendAPIRequest(
      `${configJSON.messagesApiEndPoint}?type=${this.userRole}&chat_id=${this.state.selectedChatRoom}`,
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  createMessage = async () => {
    if(this.state.attachments.length === 0 && this.state.newMessage.trim() === "") {
      this.setState({
        openChatError: "Message can not be empty",
      });
      return;
    }
    this.setState({ openChatError: "" });
    const token = await getStorageData("token");
    const formData = new FormData();
    Object.entries({
      message: this.state.newMessage,
      messenger_type: this.userRole,
      attachments: this.state.attachments as any,
    }).forEach(([keyName, value]) => {
      if (value !== null) {
        if (keyName === "attachments") {
          value.forEach((attachment: string | Blob) => {
            if (attachment !== null)
              formData.append(`message[attachments][]`, attachment);
          });
        } else {
          formData.append(`message[${keyName}]`, value as string);
        }
      }
    });
    formData.append(`chat_id`, this.state.selectedChatRoom.toString());
    this.createMessageApiCallId = sendAPIRequest(
      configJSON.messagesApiEndPoint,
      {
        method: "POST",
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  handleAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: {
        id: string;
        type: string;
        attributes: { id: number; account: IChatRoom };
      }[];
      errors?: string;
    };
    // set data when API is done
    if (response.data) {
      const chatRoomList = response.data.map((chatRoomItem) => {
        return {
          ...chatRoomItem.attributes.account,
          name: chatRoomItem.attributes.account.name?.trim(),
          roomId: chatRoomItem.attributes.id,
        };
      });
      this.setState({
        chatRoomList,
        selectedChatRoom: Number(response.data[0].id),
      });
      this.openChatMessage();
      this.showMessages();
    } else if (response.errors) {
      this.setState({ chatListError: response.errors });
    }
  };

  handleCreateMessageAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: IMessage };
      errors?: { message: string[] };
    };
    // set data when API is done
    if (response.data) {
      this.setState({
        newMessage: "",
        attachment_prevs: [null],
        attachments: [],
      });
      this.showMessages();
    } else if (response.errors) {
      this.setState({
        openChatError: response.errors.message[0],
      });
    }
  };

  handleMessagesAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: IMessage }[];
      errors?: string;
    };
    // set data when API is done
    if (response.data) {
      const messages = response.data
        .map((message) => message.attributes)
        .reverse();
      this.setState({ messages });
    } else if (response.errors) {
      this.setState({
        openChatError: response.errors,
      });
    }
  };

  handleOpenChatAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { attributes: { dock_listing: IChatReservationDetails } };
      errors?: string;
    };
    // set data when API is done
    if (response.data) {
      this.setState({
        chatReservationDetails: response.data.attributes.dock_listing,
      });
    } else if (response.errors) {
      this.setState({
        detailsError: response.errors,
      });
    }
  };

  openActionMenu = (
    event: React.MouseEvent<HTMLElement>,
    selectedChatRoom: number
  ) => {
    this.setState({
      anchorElMenu: event.currentTarget,
      selectedChatRoom,
    });
  };

  handleRemoveChatFromList = async () => {
    const token = await getStorageData("token");
    this.removeChatApiCallId = sendAPIRequest(
      `bx_block_chat/chats/${this.state.selectedChatRoom}/remove`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: {
          type: this.userRole,
        }
      }
    );
  };

  handleRemoveChatAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ anchorElMenu: null });
    const response = responseJSON as {
      message?: string;
      errors?: string;
    };
    if (response.message === "Chat removed") {
      const chatRoomList = this.state.chatRoomList.filter(
        (chatRoom) => chatRoom.roomId !== this.state.selectedChatRoom
      );
      this.setState({
        selectedChatRoom: chatRoomList?.[0]?.roomId,
        chatRoomList,
      });
      if(this.state.selectedChatRoom) {
        this.openChatMessage();
        this.showMessages();
      }
    } else if (response.errors) {
      this.setState({ chatListError: response.errors });
    }
  };

  removeAttachments = () => {
    this.setState({
      attachment_prevs: [null],
      attachments: [],
    });
  };

  groupMessagesByDate = (messages: IMessage[]): IGroupedMessages => {
    return messages.reduce(
      (groupedMessages: IGroupedMessages, message: IMessage) => {
        const date = new Date(message.created_at).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
        if (!groupedMessages[date]) {
          groupedMessages[date] = [];
        }
        groupedMessages[date].push(message);
        return groupedMessages;
      },
      {}
    );
  };

  handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    flag: string
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      this.setState({
        attachments: [...this.state.attachments, ...files] as string[],
      });
      const readFiles = files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (attachment) => {
            resolve(attachment.target?.result);
          };
          reader.readAsDataURL(file as Blob);
        });
      });

      Promise.all(readFiles).then((attachments) => {
        this.setState((prevState) => ({
          attachment_prevs:
            flag === "add"
              ? [...(prevState.attachment_prevs as any), ...attachments]
              : (attachments as string[]),
        }));
      });
    }
  };

  renderChatMessages = (message: IMessage) => {
    return (
      <List>
        {message.messenger_type !== this.userRole ? (
          <ListItem
            alignItems="center"
            style={{ alignItems: message.attachments ? "start" : "", justifyContent: "start" }}
          >
            <ListItemAvatar>
              <Avatar src={message.avatar} />
            </ListItemAvatar>
            <Box
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                backgroundColor: "#F1F5F9",
                padding: "5px 10px",
                borderRadius: "8px",
                width: message.attachments?.length ? "50%" : "max-content",
                maxWidth: "265px",
              }}
            >
              {message.attachments &&
                message.attachments.map((attachment) => {
                  return (
                    <img src={attachment.url} className="attachmentImgs" loading="lazy" />
                  );
                })}
              {message.message && <ListItemText primary={message.message} />}
              <Typography variant="caption" style={{ width: "100%", textAlign: "end" }}>
                {format(new Date(message.created_at), 'hh:mm a')}
              </Typography>
            </Box>
          </ListItem>
        ) : (
          <ListItem
            alignItems="center"
            style={{ alignItems: message.attachments ? "end" : "", justifyContent: "end" }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                backgroundColor: "#F1F5F9",
                padding: "5px 10px",
                borderRadius: "8px",
                width: message.attachments?.length ? "50%" : "max-content",
                maxWidth: "265px",
              }}
            >
              {message.attachments &&
                message.attachments.map((attachment) => {
                  return (
                    <img src={attachment.url} className="attachmentImgs" loading="lazy" />
                  );
                })}
                {message.message && <ListItemText
                  primary={message.message}
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                />}
                <Typography variant="caption" style={{ width: "100%", textAlign: "end" }}>
                  {format(new Date(message.created_at), 'hh:mm a')}
                </Typography>
            </Box>
            <ListItemAvatar>
              <Avatar src={message.avatar} />
            </ListItemAvatar>
          </ListItem>
        )}
      </List>
    );
  };

  renderIcon = (attachment: string | ArrayBuffer | null, index: number) => {
    return (
      <Box key={`preview-${index}`} className="attachment">
        <img
          src={attachment as string}
          style={{
            height: "5rem",
            width: "100%",
            borderRadius: "8px",
          }}
          loading="lazy"
        />
        <IconButton
          className="delete-icon"
          data-test-id="deleteIcon"
          onClick={() => {
            this.setState((prevState) => {
              const newAttachmentPrevs = [...prevState.attachment_prevs];
              newAttachmentPrevs.splice(index, 1);
              const newAttachments = [...prevState.attachments];
              newAttachments.splice(index, 1);
              return {
                attachment_prevs:
                  newAttachmentPrevs.length > 0 ? newAttachmentPrevs : [null],
                attachments: newAttachments.length > 0 ? newAttachments : [],
              };
            });
          }}
        >
          <CancelRoundedIcon color="primary" />
        </IconButton>
      </Box>
    );
  };

  renderOpenChatModal = () => {
    const chatInfo =
      this.state.chatRoomList.filter(
        (chatRoom) => chatRoom.id === this.state.openChatInfo.id
      ).length > 0
        ? this.state.openChatInfo
        : this.state.chatRoomList[0];
    const groupedMessages = this.groupMessagesByDate(this.state.messages);
    return (
      <Box data-test-id="btnOpenChat" className="mainChatContainer">
        <Box className="openChatContainer">
          <Box className="chatContent">
            <ArrowBackIcon
              data-test-id="backBtn"
              className="pointer backBtnVisible"
              onClick={this.closeChat}
            />
            <img
              src={chatInfo.avatar || assets.noImageAvatar}
              alt={chatInfo.name}
              className="chatDetailIcon"
              loading="lazy"
            />
            <Box>
              <Typography variant="body1">
                {chatInfo.id ? chatInfo.name || `User ${chatInfo.id}`: "Inactive User"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          id="chat-container"
          style={{
            height: this.state.attachments.length === 0 ? "559px" : "469px",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {Object.keys(groupedMessages).map((date) => {
            return (
              <Box key={date}>
                <Box className="messageDate" display="flex" alignItems="center">
                  <Box flex={1} height="1px" bgcolor="#94A3B8" />
                  <Typography variant="caption">
                    {new Date(date).toLocaleDateString() ===
                    new Date().toLocaleDateString()
                      ? "Today"
                      : date}
                  </Typography>
                  <Box flex={1} height="1px" bgcolor="#94A3B8" />
                </Box>
                {groupedMessages[date].map((message) =>
                  this.renderChatMessages(message)
                )}
              </Box>
            );
          })}
        </Box>
        <Box>
          {this.state.attachments.length > 0 && (
            <Box
              style={{
                backgroundColor: "#1275e424",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
                gap: "5px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "5px",
                  flexWrap: "wrap",
                }}
              >
                {this.state.attachments.length > 0 &&
                  this.state.attachment_prevs.map(
                    (attachment: string | ArrayBuffer | null, index: number) =>
                      this.renderIcon(attachment, index)
                  )}
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "5rem",
                    width: "4.5rem",
                    borderRadius: "8px",
                    backgroundColor: "#1275e424",
                  }}
                >
                  <AddPhotoAlternateRoundedIcon className="imageIcon" />
                  <input
                    data-test-id="addMoreAttachments"
                    type="file"
                    style={{ position: "absolute", opacity: 0 }}
                    accept="image/*"
                    multiple
                    onChange={(event) =>
                      this.handleFileInputChange(event, "add")
                    }
                  />
                </Box>
              </Box>
              <CancelRoundedIcon
                data-test-id="removeAttachments"
                color="primary"
                onClick={this.removeAttachments}
              />
            </Box>
          )}
          <CustomInput
            data-test-id="sendMsgText"
            size="small"
            variant="outlined"
            placeholder={configJSON.writeMessagePlaceholder}
            value={this.state.newMessage}
            className="messageInput"
            onChange={(event) => {
              this.setState({
                openChatError: "",
                newMessage: event.target.value,
              });
            }}
            onKeyDown={this.createMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  disablePointerEvents={!chatInfo.id}
                >
                  <IconButton component="label">
                    {this.state.newMessage ||
                    this.state.attachments.length > 0 ? (
                      <SendRoundedIcon
                        data-test-id="sendMsgIcon"
                        onClick={this.createMessage}
                      />
                    ) : (
                      <>
                        <CropOriginalIcon className="imageIcon" />
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          multiple
                          onChange={(event) =>
                            this.handleFileInputChange(event, "new")
                          }
                        />
                      </>
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            isDisable={!chatInfo.id}
          />
          {this.state.openChatError && (
            <Typography data-test-id="openChatErrorMsg" variant="body2" color="error">
              * {this.state.openChatError}
            </Typography>
          )}
          {!chatInfo.id && (
            <Typography data-test-id="inactiveUserMsg" variant="body2" color="error">
              * You cannot send message
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  renderRoomList = () => {
    const { chatRoomList } = this.state;
    return (
      <>
        {this.state.chatListError && (
          <Toast data-test-id="errorMsg" message={this.state.chatListError} />
        )}
        {chatRoomList.map((item, index: number) => (
          <Box key={`room-${index}`} className="listItemContainer">
            <Menu
              data-test-id="chatRoomItemMenu"
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={this.state.anchorElMenu}
              open={Boolean(this.state.anchorElMenu)}
              onClose={() => this.setState({ anchorElMenu: null })}
            >
              <MenuItem
                data-test-id="removeMenuItem"
                onClick={this.handleRemoveChatFromList}
              >
                Remove
              </MenuItem>
            </Menu>
            <Box className="chatContent">
              <img
                src={item.avatar || assets.noImageAvatar}
                alt={item.name}
                className="chatIcon"
                loading="lazy"
              />
              <Box
                data-test-id="btnShowChat"
                onClick={() => this.openChat(item)}
                className="pointer"
              >
                <Typography variant="body1" data-test-id="chatDetailTitle">
                  {item.id ? item.name || `User ${item.id}` : "Inactive User"}
                </Typography>
                <Typography variant="subtitle1" className="textOverflow">
                  {item.last_message || "No Message"}
                </Typography>
              </Box>
            </Box>
            <IconButton
              data-test-id="moreIcon"
              className="pointer"
              onClick={(event) => this.openActionMenu(event, item.roomId)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        ))}
      </>
    );
  };

  renderDetailModal = () => {
    return (
      <Box data-test-id="detailData" className="detailContainer">
        {this.state.detailsError && (
          <Toast data-test-id="errorMsg" message={this.state.detailsError} />
        )}
        <Typography variant="h4">{configJSON.detailsTitle}</Typography>
        <Box className="detailContent">
          <img
            src={assets.detailPhoto}
            alt={"Details"}
            className="detailIcon"
            loading="lazy"
          />
          <Box className="checkInOutContainer">
            <Box className="checkInBox">
              <Typography variant="body2">{configJSON.checkIn}</Typography>
              <Typography variant="subtitle2">
                {new Date().toLocaleDateString()}
              </Typography>
            </Box>
            <Box className="checkOutBox">
              <Typography variant="body2">{configJSON.checkOut}</Typography>
              <Typography variant="subtitle2">
                {new Date().toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          <Box className="selectGuestStyle">
            <Typography variant="body2">{configJSON.guestText}</Typography>
            <Typography variant="subtitle2">2 Guests</Typography>
          </Box>
        </Box>
        <Box className="paddingTop">
          <Typography variant="h5" className="secondaryTitle">
            {configJSON.reservationDetailsTitle}
          </Typography>
          <Typography
            variant="subtitle1"
            className="coloredSubtitle fontWeight600"
          >
            {configJSON.addressTitle}
          </Typography>
          <Typography variant="subtitle1" className="coloredSubtitle">
            {this.state.chatReservationDetails?.address || "Not available"}
          </Typography>
          <Typography
            variant="subtitle1"
            className="coloredSubtitle fontWeight600 marginTop10"
          >
            {configJSON.cancellationPolicyText}
          </Typography>
          <Typography variant="subtitle1" className="coloredSubtitle">
            Free cancellation for{" "}
            {this.state.chatReservationDetails?.cancellation_policy?.split(
              "_"
            )?.[0] || 0}{" "}
            hrs.
          </Typography>
        </Box>
      </Box>
    );
  };
  // Customizable Area End
}
