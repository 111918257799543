Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.GETApiMethod = "GET";
exports.POSTAPiMethod = "POST";
exports.labelTitleText = "KnowYourCustomerKycVerification";
exports.labelBodyText = "KnowYourCustomerKycVerification Body";
exports.getonfidoApi="https://api.onfido.com/v3/sdk_token";
exports.btnExampleTitle = "CLICK ME";

exports.kycModalTitle = "KYC Verification";
exports.kycModalInfo = 
  "You must verify your identity, this link will direct you to our verification process. The link is also shared to your email.";
exports.kycModalBtn = "Proceed to Verification";
// Customizable Area End