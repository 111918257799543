import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import {
  getStorageData,
} from "../../../framework/src/Utilities";
export const configJSON = require("./config");
import { getLastPartOfURL } from "../../../components/src/utils";


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profileDetails: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    full_phone_number: string;
    government_id: string;
    password_updated_at: string;
    profile_pic: string;
    address: object;
    emergency_contacts: object;
    bio: null | string;
  },
  isEditing: boolean,
  userRole: string,
  noBioError: string,
  successMsg: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileAPICallId: string = "";
  updateProfileAPICallId: string = "";
  oldBio: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      profileDetails: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        full_phone_number: "",
        government_id: "",
        password_updated_at: "",
        profile_pic: "",
        address: {},
        emergency_contacts: {},
        bio: '',
      },
      isEditing: false,
      noBioError: '',
      userRole : getLastPartOfURL().role,
      successMsg: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getProfileDetails();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.getUserProfileApi(message);
    this.updateUserProfileApi(message);
    // Customizable Area End
  }

  // Customizable Area Start
  getUserProfileApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiProfileCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const profileData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiProfileCallId === this.userProfileAPICallId) {
        this.oldBio = profileData.data.attributes.bio || "";
        this.setState({
          profileDetails: profileData.data.attributes
        })
      }
    }
  } 
  getProfileDetails = async() => {
    const token = await getStorageData("token"); 
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const getProfileMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userProfileAPICallId = getProfileMsg.messageId
      getProfileMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getProfilePath)
      getProfileMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getProfileMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }
  updateUserProfileApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const putProfileCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const updateProfile = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (putProfileCallId === this.updateProfileAPICallId) {
        if(updateProfile.meta.message === 'Profile Updated Successfully'){
          this.oldBio = this.state.profileDetails.bio || "";
          this.setState({ isEditing: false , successMsg: true});
          setTimeout(()=>{
            this.setState({ successMsg: false})
          },3000)
        }
      }
    }
  } 
  updateProfileDetails = async() => {
    let noBioError = '';

    if (!this.state.profileDetails.bio) {
      noBioError = 'Please enter text.';
    } 
    this.setState({ noBioError });
    if (noBioError ) {
      return;
    }
      this.setState({ noBioError: '' });  
    
    const formData = new FormData();
    this.state.profileDetails.bio && formData.append(`settings[bio]`, this.state.profileDetails.bio);
   
    const token = await getStorageData("token"); 
      const headers = {
        token: token,
      };
      const updateProfileMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateProfileAPICallId = updateProfileMsg.messageId
      updateProfileMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateProfilePath)
      updateProfileMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      updateProfileMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
      );
      updateProfileMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      runEngine.sendMessage(updateProfileMsg.id, updateProfileMsg);
  }
  handleEditClick = () => {
    this.setState({ isEditing: true });
  };
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({ 
      profileDetails : {
        ...prevState.profileDetails,
        bio: event.target.value
      }
    }));  
  };
  handleSaveClick = () => {
    this.updateProfileDetails()
  };

  handleCancelClick = () => {
    this.setState({ isEditing: false, noBioError: "", profileDetails: { ...this.state.profileDetails, bio: this.oldBio } });
  };
  // Customizable Area End
}
