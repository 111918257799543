// Customizable Area Start
import React from "react";
import {
  styled,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SignupController, {
  Props, Step
} from "./SignupController";
import GenericModal from "../../../components/src/GenericModal";
import Toast from "../../../components/src/Toast";
import DayDockerCheckbox from "../../../components/src/DayDockerCheckbox";
import PasswordCriterion from "../../../components/src/PasswordCriterion";
import DayDockerSelect from "../../../components/src/DayDockerSelect";
import { monthNames, yearsArray } from "../../../components/src/utils";
import DayDockerTextarea from "../../../components/src/DayDockerTextarea";

const assets = require("./assets");

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "Outfit, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Outfit, sans-serif !important"
      }
    },
    MuiTypography: {
      body1: {
        fontFamily: "Outfit, sans-serif"
      },
      root: {
        fontFamily: "Outfit, sans-serif"
      },
    },
  }
})

const MainWrapper = styled('div')(() => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: '0',
  margin: '0',
  color: '#0F172A',
  '& input.error': {
    borderColor: '#F87171',
  },
  '& .link': {
    background: 'none',
  },
  '& .btn': {
    fontFamily: '"Outfit", sans-serif',
    cursor: 'pointer',
    border: 0,
  },
  '& .title': {
    marginBottom: 0,
    fontSize: 24,
    fontWeight: 700,
    fontFamily: '"Josefin Sans", sans-serif',
    color: '#0F172A',
    lineHeight: '32px',
    letterSpacing: '-0.12px',
  },
  '& .sub-title': {
    marginBottom: 16,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: '"Outfit", sans-serif',
    color: '#64748B',
    lineHeight: '22px',
  },
  '& .brandname': {
    color: '#4F9FF8',
  },
  '& .field-wrapper': {
    marginBottom: 16,
  },
  '& .field-label': {
    display: 'block',
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Outfit',
    color: '#334155',
    lineHeight: '22px',
  },
  '& .field-value': {
    display: 'block',
    width: '100%',
    padding: '10px 8px',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Outfit',
    color: '#0F172A',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    background: '#FFF',
    '&::placeholder': {
      color: '#94A3B8',
    }
  },
  '& .signup-btn': {
    padding: 16,
    width: '100%',
    fontSize: 16,
    fontWeight: 700,
    color: '#FFF',
    borderRadius: '8px',
    background: '#4F9FF8',
    '&:disabled': {
      cursor: 'not-allowed',
      color: '#64748B',
      background: '#F1F5F9',
    },
  },
  '& .blue-link': {
    fontSize: 16,
    fontWeight: 700,
    color: '#4F9FF8',
  },
  '& .error-msg': {
    marginTop: 2,
    fontSize: 12,
    fontWeight: 400,
    color: '#DC2626',
    lineHeight: '18px',
  },
  '& .password-wrapper': {
    position: 'relative',
  },
  '& .agree-checkbox-label': {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
  },
  '& .toggle-pw-visibility': {
    position: 'absolute',
    zIndex: 100,
    cursor: 'pointer',
    top: '50%',
    right: 8,
    width: 24,
    height: 24,
    transform: 'translateY(-50%)',
    backgroundImage: `url(${assets.wannaSee})`,
  },
  '& .password-criteria': {
    marginBottom: 32,
  },
}));

export default class Signup extends SignupController {
  constructor(props: Props) {
    super(props);
  }

  renderEnterEmailStep() {
    const { enteredEmail, emailError, isAgreed } = this.state;

    return (
      <>
        <div className="field-wrapper">
          <label className="field-label" htmlFor="email">Email address</label>
          <input
            required
            type="text"
            id="email"
            data-test="input-email"
            value={enteredEmail}
            name="enteredEmail"
            onChange={this.handleOnChange}
            className={`field-value ${emailError ? 'error' : ''}`}
            placeholder="eg. email@address.com"
          />
          <div className="error-msg" data-test="email-error">{emailError}</div>
        </div>

        <div className="field-wrapper" style={{ marginBottom: 32 }}>
          <div style={{ display: 'flex', gap: 10, alignItems: 'start' }}>
            <DayDockerCheckbox
              id="agree-checkbox"
              checked={isAgreed}
              onChange={this.handleTermsCheckbox}
            />
            <label data-test="agree-checkbox-label" className="agree-checkbox-label" htmlFor="agree-checkbox">
              By selecting <strong>Agree and continue</strong>, I agree to the Daydocker <button type="button" onClick={this.handleClickTerms} className="btn link blue-link" style={{ padding: 0, fontSize: 14 }}>Terms of Service</button> and acknowledge the Privacy Policy.
            </label>
          </div>
        </div>
      </>
    );
  }

  renderEnterPasswordStep() {
    const {
      enteredPassword,
      enteredConfirmPassword,
      isPasswordObscured,
      confirmPasswordError,
    } = this.state;

    return (
      <>
        <div className="field-wrapper">
          <label className="field-label" htmlFor="password">Set a password</label>
          <div className="password-wrapper">
            <input
              required
              value={enteredPassword}
              name="enteredPassword"
              data-test="input-password"
              onChange={this.handleOnChange}
              type={isPasswordObscured ? 'password' : 'text'}
              id="password"
              className={`field-value`}
              placeholder="************"
            />
            <div
              onClick={this.togglePasswordVisibility}
              className="toggle-pw-visibility"
              style={{ backgroundImage: `url(${isPasswordObscured ? assets.wannaSee : assets.notWannaSee})` }}
            ></div>
          </div>
        </div>

        <div className="field-wrapper">
          <label htmlFor="confirm-password" className="field-label">Confirm password</label>
          <div className="password-wrapper">
            <input
              required
              value={enteredConfirmPassword}
              name="enteredConfirmPassword"
              data-test="input-confirm-password"
              onChange={this.handleOnChange}
              type={isPasswordObscured ? 'password' : 'text'}
              className={`field-value ${confirmPasswordError ? 'error' : ''}`}
              id="confirm-password"
              placeholder="************"
            />
            <div
              data-test="toggle-password-visibility"
              onClick={this.togglePasswordVisibility}
              className="toggle-pw-visibility"
              style={{ backgroundImage: `url(${isPasswordObscured ? assets.wannaSee : assets.notWannaSee})` }}
            ></div>
          </div>
          <div className="error-msg" data-test="confirm-password-error">{confirmPasswordError}</div>
        </div>

        <div className="password-criteria">
          <PasswordCriterion description="Min. 8 characters" met={this.metMin8Characters} customStyle={{ wrapper: { marginBottom: 4 } }} />
          <PasswordCriterion description="1 number" met={this.metAtLeastOneNumber} customStyle={{ wrapper: { marginBottom: 4 } }} />
          <PasswordCriterion description="1 special character" met={this.metAtLeastOneSpecialCharacter} customStyle={{ wrapper: { marginBottom: 4 } }} />
        </div>
      </>
    );
  }

  renderVerifyEmailStep() {
    const { enteredVerifyCode } = this.state;

    return (
      <>
        <div style={{ marginBottom: 32 }} className="field-wrapper">
          <label className="field-label" htmlFor="verify-code">Enter code</label>
          <input
            style={{ textAlign: 'center' }}
            id="verify-code"
            data-test="input-verify-code"
            value={enteredVerifyCode}
            name="enteredVerifyCode"
            onChange={this.handleOnChange}
            type="text"
            className={`field-value`}
            placeholder="123456"
          />
        </div>
        <div style={{ marginBottom: 40, fontSize: 14 }}>
          <span>Didn't receive the code?</span>
          <button
            data-test="resend-verify-code"
            type="button"
            style={{ fontSize: 14 }}
            onClick={this.resendVerifyCode}
            className="btn link blue-link"
          >Resend</button>
        </div>
      </>
    );
  }

  renderEnterRequiredFieldsStep() {
    const {
      enteredFirstName,
      enteredLastName,
      selectedMonth,
      selectedDay,
      selectedYear,
      tooYoungError
    } = this.state;

    return (
      <>
        <div className="field-wrapper">
          <label className="field-label" htmlFor="first-name">First name</label>
          <input
            required
            id="first-name"
            data-test="input-first-name"
            value={enteredFirstName}
            name="enteredFirstName"
            onChange={this.handleOnChange}
            type="text"
            className={`field-value `}
            placeholder="eg. Joe"
          />
        </div>
        <div className="field-wrapper">
          <label className="field-label" htmlFor="last-name">Last name</label>
          <input
            required
            id="last-name"
            data-test="input-last-name"
            value={enteredLastName}
            name="enteredLastName"
            onChange={this.handleOnChange}
            type="text"
            className={`field-value `}
            placeholder="eg. James"
          />
        </div>
        <div className="field-wrapper" style={{ marginBottom: 40 }}>
          <label className="field-label" htmlFor="dob">Date of Birth</label>
          <div style={{ display: 'flex', gap: 10 }}>
            <DayDockerSelect
              value={selectedMonth ? monthNames[+selectedMonth - 1].label : ''}
              name="selectedMonth"
              onChange={this.handleDateChange}
              itemList={monthNames}
              placeholder="Month"
              customStyles={{ formControl: { flex: 1 } }}
            />
            <DayDockerSelect
              value={selectedDay}
              name="selectedDay"
              onChange={this.handleDateChange}
              itemList={this.daysArray}
              placeholder="Day"
              customStyles={{ formControl: { flex: 1 } }}
            />
            <DayDockerSelect
              value={selectedYear}
              name="selectedYear"
              onChange={this.handleDateChange}
              itemList={yearsArray}
              placeholder="Year"
              customStyles={{ formControl: { flex: 1 } }}
            />
          </div>
          <div className="error-msg" data-test="too-young-error">{tooYoungError}</div>
        </div>
      </>
    );
  }

  renderEnterOptionalFieldsStep() {
    const { profilePreview, knowUsFrom, moreFeedback } = this.state;

    return (
      <>
        <div className="field-wrapper">
          <label data-test="profile-picture-label" className="field-label" htmlFor="profile-picture">
            Upload a profile picture &nbsp;
            <span style={{ color: '#94A3B8' }}>(Optional)</span>
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="profile-picture-input"
              type="file"
              accept="image/*"
              onChange={this.handleProfileImageChange}
              style={{ display: 'none' }}
              ref={this.profileInputRef}
            />
            <label htmlFor="profile-picture-input" style={{ cursor: 'pointer', display: 'inline-block' }}>
              <div
                style={{
                  boxSizing: 'border-box',
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  border: '4px solid #F0E5FF',
                  backgroundColor: '#000',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                {profilePreview ? (
                  <img src={profilePreview as string} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                  <img src={assets.cameraIcon} alt="Upload" style={{ width: '24px', height: '24px' }} />
                )}
              </div>
            </label>
            {profilePreview && (
              <button
                type="button"
                onClick={this.triggerProfileInput}
                className="btn link"
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#4F9FF8',
                  textDecoration: 'underline',
                }}
              >
                Change photo
              </button>
            )}
          </div>
        </div>
        <div className="field-wrapper" style={{ marginBottom: 40 }}>
          <label htmlFor="know-us-from" className="field-label">
            How did you hear about us? <span style={{ color: '#94A3B8' }}>(Optional)</span>
          </label>
          <div style={{ display: 'flex', marginBottom: 16 }}>
            <DayDockerSelect
              customStyles={{ formControl: { flex: 1 } }}
              value={knowUsFrom}
              name="knowUsFrom"
              onChange={this.handleOnChange}
              itemList={[
                { label: 'Internet search', value: 'Internet search' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'Family/Friend', value: 'Family/Friend' },
                { label: 'Other', value: 'Other' },
              ]}
              placeholder="eg. Facebook"
            />
          </div>
          <DayDockerTextarea
            value={moreFeedback}
            name="moreFeedback"
            onChange={this.handleOnChange}
            placeholder="How you came across our platform"
          />
        </div>
      </>
    );
  }

  renderFormContent() {
    const { currentStep } = this.state;

    switch (currentStep) {
      case Step.EnterEmail:
        return this.renderEnterEmailStep();
      case Step.EnterPassword:
        return this.renderEnterPasswordStep();
      case Step.VerifyEmail:
        return this.renderVerifyEmailStep();
      case Step.EnterRequiredFields:
        return this.renderEnterRequiredFieldsStep();
      case Step.EnterOptionalFields:
        return this.renderEnterOptionalFieldsStep();
      default:
        return null;
    }
  }

  render() {
    const { errorMessage, isLoading, currentStep, successMessage } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <GenericModal dataTest="signup-modal" open={this.props.isOpen} onClose={this.props.onClose} onBack={this.backOrNull}>
          <MainWrapper data-test-id="mainWrapper">
            <div 
              className="title" 
              data-test="title" 
              dangerouslySetInnerHTML={{ __html: this.title }}
            />
            <div 
              className="sub-title"
              dangerouslySetInnerHTML={{ __html: this.subTitle }}
            />
            {errorMessage && <Toast data-test="toast" message={errorMessage} />}
            {successMessage && <Toast data-test="toast" message={successMessage} type="success" />}
            <form 
              onSubmit={this.handleSubmit}
              data-test="signup-form" 
              style={{ margin: 0 }}
            >
              {this.renderFormContent()}

              <button
                disabled={this.isDisabled || isLoading}
                data-test="signup-btn"
                className="btn signup-btn"
                // onClick={this.handleSubmit}
              >
                {this.submitBtnText}
              </button>

              {currentStep === Step.EnterEmail && (
                <div style={{ marginTop: 24, textAlign: 'center' }}>
                  <span>Already have an account?</span>
                  <button
                    type="button"
                    data-test="go-to-login"
                    onClick={this.goToLogin}
                    className="btn link blue-link">Login</button>
                </div>
              )}
            </form>
          </MainWrapper>
        </GenericModal>
      </ThemeProvider>
    );
  }
}

// Customizable Area End
