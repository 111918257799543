import React from "react";
// Customizable Area Start
import {
  Button,
  Container,
  Grid,
  Typography,
  AppBar,
  ThemeProvider,
  Box,
  createTheme,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputBase, FormControl, RadioGroup, FormControlLabel, InputAdornment, Modal
} from '@material-ui/core';
import { styled } from '@mui/system';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { imgHeaderLogo, progressBar2, grillImg } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import { getLastPartOfURL } from "../../../components/src/utils";

const BpIcon1 = styled('span')(({ theme }) => ({
  height: 20,
  width: 20,
  borderRadius: "6px",
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  marginLeft:"-9px",
  'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
      boxShadow: 'none',
  },
}));

const BpCheckedIcon1 = styled(BpIcon1)({
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  backgroundColor: '#4F9FF8',
  '&::before': {
    height: 20,
    width: 20,
    display: 'block',
      backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#106ba3',
  },
});
const BpCheckbox1 = (props: CheckboxProps) => {
  return (
      <Checkbox
      checkedIcon={<BpCheckedIcon1 />}
      color="default"
      icon={<BpIcon1 />}
      disableRipple
          inputProps={{ 'aria-label': 'list-data' }}
          {...props}
      />
  );
}
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
  },
  'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#4F9FF8',
  border: "none",
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#106ba3',
  },
});
const BpRadio = (props: RadioProps) => {
  return (
      <Radio
          disableRipple
          color="default"
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
          {...props}
      />
  );
}
const BpIcon2 = styled('span')(({ theme }) => ({
  borderRadius: "50%",
  width: 24,
  height: 24,
  marginLeft:"-9px",
  boxShadow: 'inset 0 0 0 1.5px #0F172A, inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: 'white',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  '&::before': {
    height: 10,
    width: 10,
    display: 'block',
      backgroundImage: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M8 2a.75.75 0 0 1 .75.75V7.25h4.5a.75.75 0 0 1 0 1.5H8.75v4.5a.75.75 0 0 1-1.5 0v-4.5H2.75a.75.75 0 0 1 0-1.5h4.5V2.75A.75.75 0 0 1 8 2z' fill='%230F172A' stroke='%230F172A' stroke-width='2'/%3E%3C/svg%3E\")",
      content: '""',
  },  
  'input:disabled ~ &': {
      boxShadow: 'none',
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
},
}));

const BpCheckedIcon2 = styled(BpIcon2)({
  backgroundColor: 'white',
  boxShadow: 'inset 0 0 0 2px #059669, inset 0 -1px 0 rgba(16,22,26,.1)',
  '&::before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12.308 4.612c-.28 0-.53.11-.71.29L6.68 9.883l-1.748-1.739a1.003 1.003 0 00-1.193 1.033l2.21 2.329c.18.18.43.29.71.29s.53-.11.693-.29l5.526-5.61A1.003 1.003 0 0012.512 4.706z' fill='%23059669'/%3E%3C/svg%3E\")", // #059669 color icon
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#f0f0f0',
  },
});

const BpCheckbox2 = (props: CheckboxProps ) => {
  return (
      <Checkbox
          color="default"
          disableRipple
          icon={<BpIcon2 />}
          checkedIcon={<BpCheckedIcon2 />}
          inputProps={{ 'aria-label': 'round-checkbox' }}
          {...props}
      />
  );
}

const theme = createTheme({
  palette: {
      primary: {
          main: "#4F9FF8",
      },
  },
  overrides: {
      MuiTypography: {
          h5: {
              fontFamily: "Josefin Sans",
              fontSize: "1.2rem",
              fontWeight: 700,
              color: "#0F172A",
          },
          body1: {
              fontFamily: "Outfit",
              fontSize: "1rem",
              fontWeight: 400,
              color: "#64748B",
              textTransform: "none",
          },
          subtitle1: {
              fontFamily: "Outfit",
              fontSize: "0.9rem",
              fontWeight: 400,
              color: "#64748B",
          },
        colorTextSecondary:{
          color: "#0F172A",
        }
      },     
      MuiFormControlLabel: {
          label: {
              color: "#0F172A",
              fontSize: "16px",
              fontFamily: "Outfit",
              '&$disabled': {
                  color: '#64748B',
              },
          },
      },
      MuiRadio: {
          root: {
              color: "#4F9FF8",
              '&$checked': {
                  color: "#106ba3",
              },
          },
      },
      MuiDivider: {
          root: { margin: "15px 0" },
      },
      MuiButton: {
          root: {
              borderRadius: "8px",
              padding: "16px 32px",
              margin: "10px 0 30px 0",
          },
          containedPrimary: {
              fontFamily: "Outfit",
              fontSize: "16px",
              fontWeight: 700,
              color: "white",
              textTransform: "capitalize",
          },
      },
      MuiCheckbox: {
          root: {
              '& .MuiSvgIcon-root': {
                  borderRadius: '6px',
                  strokeWidth: 1,
                  color: '#CBD5E1',
              },
              '&:hover': {
                  backgroundColor: 'transparent',
              },
              '&.Mui-checked': {
                  color: '#4F9FF8',
              },
          },
      }
  },
});
// Customizable Area End

import DockListingFeaturesController, {
  Props,
  configJSON,
} from "./DockListingFeaturesController.web";

export default class DockListingFeatures extends DockListingFeaturesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const chunks = this.chunkArray(this.state.featureData, 5);
    const chunkData = this.chunkArray(this.state.addOnsData, 5);
    return (
      <ThemeProvider theme={theme}>
        <Wrapper data-test-id="calendarModalWrapper">
          <Container maxWidth="lg">
            <AppBar position="relative" elevation={0} className="headerContent">
              <img src={imgHeaderLogo} className="logoContent" loading="lazy" />
            </AppBar>
            <Button 
              data-test-id="backBtn"
              startIcon={<ArrowBackIosRoundedIcon />}
              style={{ textTransform: "none" }}
              className="backButton"
              onClick={() => {
                this.state.draftDataIds.id === Number(getLastPartOfURL().url)
                  ? this.handleNavigation("DockListingForm")
                  : this.handleNavigation("ManageListingDetails")
              }}
            >Back</Button>

            <Box className="bodyContent">
              <Typography variant="h5">
                {configJSON.listingFeatures}
              </Typography>

              <Grid container className="addressBox" >
                {this.state.featureData && chunks.map((chunk, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <List>
                      {chunk.map((feature) => (
                        <ListItem key={feature.id} style={{ padding: "0 40px 0 0" }} >
                          <ListItemIcon style={{ minWidth: 30 }}>
                            <BpCheckbox1 onChange={() => this.handleFeatureChange(feature.attributes.id)} 
                              checked={this.state.featureIds.includes(feature.attributes.id)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontFamily: "Outfit",
                                fontSize: "16px",
                                fontWeight: 400,
                                color: "#0F172A",
                              },
                            }}
                            primary={feature.attributes.name}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                ))}
              </Grid>
              <Divider className="marginBottom" />
              <Box className="paddingBottom">
                <Typography variant="h5" >
                  {configJSON.addOnsText}
                </Typography>
                <Typography variant="subtitle1" className="featureText" style={{marginTop:"16px"}}>
                  {configJSON.noteForAddOns}
                </Typography>
                <Typography variant="subtitle1" className="featureText" style={{marginTop:"8px"}}>
                  {configJSON.includeListing}
                </Typography>
                <Grid container className="addressBox">
                  {chunkData.map((chunk, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <List data-testId='feature-data' style={{ padding: 0 }}>
                        {chunk.map((feature) => (
                          <ListItem style={{ padding: "0 40px 0 0" }} key={feature.id}>
                            <ListItemIcon style={{ minWidth: 30 }}>
                              <BpCheckbox2 onChange={() => this.handleCheckedModel(feature)} 
                                checked={this.state.addOnsIds.includes(feature.attributes.id)}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  fontFamily: "Outfit",
                                  color: "#0F172A",
                                },
                              }}
                              primary={feature.attributes.name}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  ))}
                </Grid>                
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.modalOpen}
                  className='modal'
                  closeAfterTransition
                  onClose={() => this.handleClose(false)}
                >
                  <Box style={{ ...webStyle.modal, transform: "translate(-50%, -50%)", position: "absolute",boxSizing: "border-box" }} >
                    <div style={{width:"327px"}}>
                      <div style={webStyle.closeIcon} role="close-button" onClick={() => this.handleClose(false)}><CloseIcon /></div>
                      <Box style={webStyle.addOnsIcon}>
                        <img src={grillImg} alt="Add ons icon" style={{marginTop:"-10px"}} loading="lazy" />
                        <div style={webStyle.title}><Typography variant="h5" id="transition-modal-title" >
                          {this.state.addOnsName[this.state.addOnsName.length-1]}
                        </Typography></div>
                      </Box>
                      <div style={{ marginTop: "24px"}}>
                        <Typography style={webStyle.freeOrPaid} >                        
                          {configJSON.addOnsCharges}
                        </Typography>
                        <InputBase value={0 | this.state.addOnCharge} style={webStyle.chargeOf} onChange={this.handleAddOnCharge}
                          inputProps={{'data-testId': 'add-on-charge',
                            style:{display: 'flex',alignItems: "center", justifyContent: "center"}
                          }}
                          placeholder="Enter charges"
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        /> 
                      </div>
                      <Divider className="marginBottom" />
                      <Box style={{...webStyle.buttonBox, flexDirection:"column"}}>
                        <Button style={{ textTransform: "capitalize", ...webStyle.addBtn }} onClick={this.updateAddOnsCharge}>Add</Button>
                      </Box>
                    </div>
                  </Box>
                </Modal>
              </Box>
              <Divider className="marginBottom" />
              <Box>
                <Typography variant="h5" style={{marginBottom:"16px"}}>
                  {configJSON.dockAccess}
                </Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-customized-radios"
                    value={this.state.dockAccess}
                    name="customized-radios"
                    data-testId="radio-buttons"
                    onChange={this.handleRadioChange}
                    style={{ gap: "20px" }}
                  >
                    <InputBase
                      className={`radioInput ${this.state.dockAccess === 'Boat only access' ? 'selected' : 'unSelected'}`}
                      startAdornment={
                        <InputAdornment position="start">
                          <FormControlLabel
                            control={<BpRadio />}
                            value="Boat only access"                            
                            label="Boat only access"
                            style={{
                              fontWeight: this.state.dockAccess === 'Boat only access' ? 600 : 400,
                              fontFamily: "Outfit",
                              fontSize: "16px",
                              color: "#0F172A",
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                    <InputBase
                      className={`radioInput ${this.state.dockAccess === 'Access dock from property address' ? 'selected' : 'unSelected'}`}
                      startAdornment={
                        <InputAdornment position="start">
                          <FormControlLabel
                            control={<BpRadio />}
                            label="Access dock from property address"
                            value="Access dock from property address"
                            style={{
                              fontWeight: this.state.dockAccess === 'Access dock from property address' ? 600 : 400,
                              fontFamily: "Outfit",
                              fontSize: "16px",
                              color: "#0F172A",
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                {this.state.dockAccess === 'Access dock from property address' && <>
                  <Typography className="parkingText">
                    {configJSON.numParking}
                  </Typography>
                  <InputBase value={0 | this.state.noOfParking} placeholder="Number of parking places" className="numberOf" onChange={this.handleParkingCount}
                    inputProps={{
                      'area-label': 'parking_places',
                      style:{display: 'flex',alignItems: "center", justifyContent: "center", marginTop:"6px"}
                    }} />
                </>}
              </Box>
              {this.state.dockAccessMsg && (
                    <div style={webStyle.errMsg}>
                      {this.state.dockAccessMsg}
                    </div>
                  )}
                {this.state.dockParkingMsg && (
                    <div style={webStyle.errMsg}>
                      {this.state.dockParkingMsg}
                    </div>
                  )}
              <Button variant="contained" color="primary" data-test-id="nextBtn" style={{ marginTop: "40px"}} onClick={this.handleNextPage}>
                {configJSON.nextBottonText}
              </Button>
            </Box>
          </Container>
          <Box className="progressBar">
            <img alt="progress-bar" src={progressBar2} loading="lazy" />
          </Box>
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  errMsg:{ color: 'red', fontSize:'12px', marginTop:'20px' },
  addBtn: {
    color: "#fff", background: "#4F9FF8", width: "327px", fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 700,
  },
  cancelBtn: {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 700, marginBottom: "0px",marginTop: "0px",
  },
  chargeOf: {
    width: "322px",
    height: "44px",
    padding: "10px 8px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    marginBottom: "10px",
    marginTop: "6px" 
  },
  radioButtons: {
    width: "62px",
    height: "34px",
    padding: "6px 16px",
    borderRadius: "32px",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Outfit",
  },
  buttonBox:{
    display: 'flex',alignItems: "center", justifyContent: "center"
  },
  freeOrPaid: {
    fontWeight: 400,
    fontSize: "13px",
    fontFamily: "Outfit", color: "#0F172A"
  },
  title: {
    display: 'flex', marginTop: 0,
    alignItems: "center", justifyContent: "center"
  },
  addOnsIcon: {
    marginTop: "12px",
    display: 'flex',
    gap: "4px",alignItems: "center"
  },
  closeIcon: {
    marginTop: "20px",
    display: 'flex',
    justifyContent: 'end',
    cursor:"pointer"
  },
  modal: {    
    width: 365,    
    height: 318,
    borderRadius: 32,
    backgroundColor: "#fff",
    color: "#000",
    top: "50%",
    left: "50%",
    display: 'flex',
    justifyContent: 'center',
  }
}
const Wrapper = styled("div")(({ theme }) => ({
  "& .modal": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  "& .numberOf": {
    width: "375px",
    '@media (max-width: 450px)': {
      width: "100%",
    },
    height: "44px",
    padding: "16px 0 16px 16px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  "& .featureTextSelected": {
    fontWeight: 600,
  },
  "& .radioInput": {
    width: "375px",
    '@media (max-width: 450px)': {
      width: "100%",
    },
    height: "52px",
    padding: "16px 0 16px 16px",
    borderRadius: "8px",
    border: "1px solid #94A3B8",
  },
  "& .selected": {
    backgroundColor: '#EDF5FE',
    borderColor: '#4F9FF8',
  },
  "& .unSelected": {
    backgroundColor: '',
    borderColor: '#94A3B8',
  },
  "& .parkingText": {
    marginTop: "16px",
    marginBottom: "4px",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Outfit",
    color: "#0F172A"
  },
  "& .backButton": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Outfit",
    color: "#64748B",
    marginTop:"30px",
  },
  "& .featureText": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Outfit", color: "#0F172A"
  },
  "& .headerContent": {
    width: "100%", height: "auto", backgroundColor: "#FFFFFF", padding: "10px 0"
  },
  "& .logoContent": {
    width: "20%",
    height: "auto",
    minWidth: "175px",
  },
  "& .bodyContent": {
    margin: "-60px 240px -30px 240px",
    '@media (max-width: 900px)': {
      margin: "0 10px",
    },
  },
  "& .addressBox": {
    margin: 0,
  },
  "& .paddingTop": {
    paddingTop: "8px",
  },
  "& .paddingBottom": {
    paddingBottom: "8px",
  },
  "& .paddingRight": {
    paddingRight: "5px",
  },
  "& .paddingLeft": {
    paddingLeft: "5px",
  },
  "& .marginTop": {
    marginTop: "12px",
  },
  "& .marginBottom": {
    marginBottom: "32px",
  },
  "& .progressBar": {
    width: "100%",
  },
  "& .progressBar img": {
    width: "100%",
  }
}));

// Customizable Area End
