import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Avatar,
    IconButton,
    Button,
    Tooltip,
  } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { imgListing } from "../../../blocks/landingpage/src/assets";
import { userLogo } from "../../../blocks/reservations/src/assets";
import CloseIcon from '@material-ui/icons/Close';
import GenericModal from "../../../components/src/GenericModal";
import { Pagination } from "@material-ui/lab";

const theme = createTheme({
    overrides: {
      MuiTypography: {
        root: {
          fontFamily: "Josefin Sans",
        },
        h3: {
          fontFamily: "Josefin Sans",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "32px",
        },
        h5: {
          fontFamily: "Outfit",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "20px",
        },
        body1: {
          fontFamily: "Outfit",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "22px",
          color: "#64748B",
        },
      },
      MuiButton: {
        root: {
          borderRadius: "8px",
          padding: "16px",
        },
        containedPrimary: {
          fontFamily: "Outfit",
          fontSize: "16px",
          fontWeight: 700,
          color: "white",
          backgroundColor: "#4F9FF8",
          textTransform: "capitalize",
          borderRadius: "8px",
          '&:hover': {
            backgroundColor: "#4F9FF8",
          }
        },
        textPrimary: {
          fontFamily: "Outfit",
          fontSize: "16px",
          fontWeight: 700,
          color: "#4F9FF8",
          textTransform: "capitalize",
          margin: "5px 0px",
        }
      },
      MuiTooltip: {
        tooltip: {
          padding: 5,
          fontFamily: "Outfit",
          fontSize: "1rem"
        }
      },
    },
  });

  import FavouritesController, {
    Props,
    configJSON,
} from "./FavouritesController.web";
import Toast from "../../../components/src/Toast";
import NoDataFound from "../../../components/src/NoDataFound.web";

const assets = require("./assets");
// Customizable Area End

export default class Favourites extends FavouritesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isDeleteDialogOpen, selectedFavourite } = this.state;
    // Customizable Area End
    return (
        // Customizable Area Start
        <>
        <ThemeProvider theme={theme}>
            <MainWrapper data-test-id="mainWrapper">
                <Container maxWidth="lg" style={{ minHeight: '71vh', marginBottom: "20px" }}>
                    <Header
                        navigation={this.props.navigation}
                        role={"boater"}
                        isLoggedIn={true}
                    />
                    <Box mt={4}>
                        <Box my={3}>
                            <Typography variant="h3" data-test-id="pageTitle">
                              {configJSON.titleText} ({this.state.total_count})
                            </Typography>
                        </Box>
                          {this.state.favorites.length > 0 ?
                            <Box>
                              <Grid container spacing={5}>
                                {this.state.favorites.map((favorite) => (
                                  <Grid item xs={12} sm={6} md={4} lg={4} key={favorite.id}>
                                      <Card className="card-root">
                                      <Box className="cardMediaWrapper">
                                        <CardMedia
                                            className="card-media"
                                            image={favorite?.dock_listing?.images?.[0] || imgListing}
                                            title={favorite?.dock_listing?.listing_title}
                                            component="img"
                                            loading="lazy"
                                        />
                                      </Box>
                                          <Box className="cardContentWrapper">
                                              <CardContent className="card-content">
                                                  <Box>
                                                  <Tooltip
                                                    title={
                                                      favorite?.dock_listing?.about?.trim().length > 0
                                                      ? favorite?.dock_listing?.about
                                                      : ""
                                                    }
                                                  >
                                                      <Typography
                                                        variant="body1"
                                                        style={{
                                                          textOverflow: "ellipsis",
                                                          overflow:"hidden", 
                                                          width: "80%",
                                                          whiteSpace: "nowrap"
                                                        }}
                                                      >
                                                          {favorite?.dock_listing?.about || "Not Provided"}
                                                      </Typography>
                                                    </Tooltip>
                                                    <Typography variant="h5">
                                                        {favorite?.dock_listing?.listing_title || "Not Provided"}
                                                    </Typography>
                                                  </Box>
                                                  <Box mt={1} display="flex" alignItems="center">
                                                      <Avatar src={favorite?.dock_listing?.host?.avatar || userLogo} className="profileImg"/> 
                                                      <Typography variant="body1" color="textSecondary">
                                                            {configJSON.hostedBy} {favorite?.dock_listing?.host?.name.toString().trim() || "Unknown"}
                                                      </Typography>
                                                  </Box>
                                              </CardContent>
                                          </Box>
                                          <IconButton 
                                            className="delete-icon"
                                            data-test-id="deleteIcon" 
                                            onClick={() => this.handleDeleteClick(favorite)}
                                          >
                                              <CloseIcon />
                                          </IconButton>
                                      </Card>
                                  </Grid>
                                ))}
                              </Grid>
                              <Box className="pagination">
                                <Pagination
                                  data-test-id="pagination"
                                  count={Math.ceil(this.state.total_pages)}
                                  siblingCount={0}
                                  page={this.state.current_page}
                                  onChange={this.handlePageChange}
                                />
                                <Typography variant="subtitle1">
                                  {(this.state.current_page - 1) * this.itemsPerPage + 1} - {this.state.current_page * this.itemsPerPage > this.state.total_count
                                    ? this.state.total_count
                                    : this.state.current_page * this.itemsPerPage} of {this.state.total_count} results
                                </Typography>
                              </Box>
                            </Box>
                            : 
                            <NoDataFound
                              image={assets.noFavoritesIcon}
                              message="No favorites!"
                              name="No Favorites"
                            />
                          }
                    </Box>
                </Container>
                <Footer navigation={this.props.navigation} />
            </MainWrapper>
        </ThemeProvider>
        <GenericModal dataTest="delete-favourite-modal" data-test-id="deleteModal" open={isDeleteDialogOpen} onBack={this.onCloseDialog} isCloseIcon={false}>
          <ThemeProvider theme={theme}>
            <MainWrapper>
              <Box>
                {this.state.errorMsg && <Toast message={this.state.errorMsg} />}
                <Typography variant="h3">{configJSON.confirmDeletationTitle}</Typography>
                <Typography variant="body1">Do you want to delete {selectedFavourite.dock_listing?.listing_title} from favorites?</Typography>
                <Box mt={3}>
                  <Button
                    color="primary" 
                    variant="text"
                    data-test-id="cancelBtn"
                    onClick={this.onCloseDialog}
                    fullWidth
                  >
                    {configJSON.cancelBtnText}
                  </Button>
                  <Button
                    data-test-id="deleteButton"
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    onClick={this.removeFromFavorites}
                  >
                    {configJSON.deleteBtnText}
                  </Button>
                </Box>
              </Box>
            </MainWrapper>
          </ThemeProvider>
        </GenericModal>
        </>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const MainWrapper = styled("div")(({ theme }) => ({
  "& .card-root":{
    height: "94px",
    display: "flex", 
    position: "relative",
    transition: 'transform 0.3s ease-in-out',
    '&:hover': { 
      transform: "scale(1.03)", 
    },
    '&:hover .delete-icon': { 
      opacity: 1 
    },
    padding: "16px", 
    borderRadius: "12px",
  },
  "& .card-media": {
    width: "96px",
    height: "96px",
    borderRadius: "16px",
  },
  "& .cardContentWrapper": {
    display: "flex",
    padding: "6px 0",
    maxWidth: "73%"
  },
  "& .card-content": {
    width: "100%",
    padding: "0px 8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  "& .profileImg": {
    width: "24px",
    height: "24px",
    marginRight: "5px",
  },
  "& .delete-icon": {
    position: 'absolute', 
    top: 8, 
    right: 8, 
    opacity: 0, 
    transition: 'opacity 0.3s' 
  },
  "& .pagination": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 50px 0 0",
    gap: 40,
    "& button": {
      textDecoration: "underline",
    },
  },
  "& .Mui-selected": {
    fontWeight: 700,
    backgroundColor: "transparent",
    textDecoration: "none !important",
  },
  [theme.breakpoints.down(500)]: {
    "& .pagination": {
      padding: "30px 20px 0 20px",
      gap: 5,
    },
  },
}));
// Customizable Area End