// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { imgAcceptReservations, imgGetPaid, imgListDock, ImgBackground } from "./assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import ContactUsModal from "../../emailnotifications2/src/ContactUsModal.web";
import Login from "../../../blocks/email-account-login/src/Login.web";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans !important",
      },
      h1: {
        fontSize: "48px",
        fontWeight: 700,
        lineHeight: "56px",
      },
      h2: {
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "44px",
      },
      body1: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "32px",
      },
      h3: {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
      },
      body2: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Josefin Sans",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: '24px',
      },
      contained: {
          color: "#FFFFFF",
          backgroundColor: "#4F9FF8",
          borderColor: "#4F9FF8 !important",
          textTransform: "none",
          "&:hover": {
              backgroundColor: "#FFFFFF",
              color: "#4F9FF8",
            }
      },
      outlined: {
        color: "#64748B",
        border: "1px solid #64748B !important",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#4F9FF8",
            color: "#FFFFFF",
          }
    },
    },
  }
});

import JobListingController, {
  configJSON,
} from "./JobListingController.web";

class JobListing extends JobListingController {
  static JobListing: JobListing;
  render() {
    return (
      <>
      <ThemeProvider theme={theme}>
        <Login
          data-test-id="login-modal"
          isOpen={this.state.loginModal}
          onClose={() => {
            this.setState({ loginModal: false });
          }}
          navigation={this.props.navigation}
          id="login modal"
        />
            <MainWrapper data-test-id="jobListingContainer">
              <Container maxWidth="lg">
              <Header
                  navigation={this.props.navigation}
                  role="host"
                />
                </Container>
                
                <Box my={16}>
                  <Container maxWidth="sm" className="listingContainer">
                    <Box>
                      <Typography data-test-id="pageTitleText" variant="h1" align="center" >{configJSON.headerText}</Typography>
                      <Typography variant="body1" align="center" style={{marginTop:'16px'}}>{configJSON.bodyText}</Typography>
                    </Box>
                    <Box 
                      display="flex" 
                      justifyContent="center" 
                      my={3}
                    >
                      <Button 
                        variant="contained" 
                        className="btn"
                        data-test-id = "listYourDockBtn"
                        onClick={() => this.handleListYourDockBtnClick()}
                      >
                          {configJSON.btnListYourDockText}
                      </Button>
                    </Box>
                  </Container>
                </Box>
                <Box className="hostingOnDayDockerWrapper">
                    <Typography variant="h2" align="center" className="hostingOnDayDockerText">{configJSON.hostingOnDayDockerText}</Typography>
                    <Container className="listingContainer">
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid container item md={4} justifyContent="center">
                                <Box className="abtDayDockerWrapper">
                                    <img src={imgListDock} className="abtDayDockerImgs" />
                                    <Box my={3}>
                                        <Typography align="center" variant="h3">{configJSON.listYourDockTitle}</Typography>
                                        <Typography align="center" variant="body2">{configJSON.listYourDockDescription}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid container item md={4} justifyContent="center">
                                <Box className="abtDayDockerWrapper">
                                    <img src={imgAcceptReservations} className="abtDayDockerImgs" />
                                    <Box my={3}>
                                        <Typography align="center" variant="h3">{configJSON.acceptReservationsTitle}</Typography>
                                        <Typography align="center" variant="body2">{configJSON.acceptReservationsDescription}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid container item md={4} justifyContent="center">
                                <Box className="abtDayDockerWrapper">
                                    <img src={imgGetPaid} className="abtDayDockerImgs" />
                                    <Box my={3}>
                                        <Typography align="center" variant="h3">{configJSON.getPaidTitle}</Typography>
                                        <Typography align="center" variant="body2">{configJSON.getPaidDescription}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box display="flex" justifyContent="center" marginBottom='24px'>
                        <Button 
                          variant="contained" 
                          className="btn"
                          data-test-id = "listYourDockBtn"
                          onClick={() => this.handleListYourDockBtnClick()}
                        >
                          {configJSON.btnListYourDockText}
                        </Button>
                    </Box>
                </Box>
                <Box className="protectionGuaranteeSection">
                    <Box className="protectionGuaranteeTexts">
                        <Typography variant="h2" align="center" className="protectionGuaranteeTitle">{configJSON.protectionGuaranteeTitle}</Typography>
                        <Typography variant="body1" align="center" className="protectionGuaranteeDescription">{configJSON.protectionGuaranteeDescription}</Typography>
                    </Box>
                </Box>
                <Container maxWidth="md">
                  <Box display="grid" justifyContent="center" gridGap={24} my={10}>
                    <Box>
                      <Typography variant="h2" align="center" className="protectionGuaranteeTitle">{configJSON.learneMoreTitle}</Typography>
                      <Typography variant="body1" align="center" className="protectionGuaranteeDescription">{configJSON.learnMoreDescription}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" gridGap={16}>
                      <Button data-test-id="FAQBtn" variant="contained" className="btn" onClick={() => this.handleNavigation('FAQs')}>{configJSON.btnFAQsText}</Button>
                      <Button data-test-id="ContactusBtn" variant="outlined" className="btn" onClick={() => this.setState({ openModal: true })}>{configJSON.btnContactUsText}</Button>
                    </Box>
                  </Box>
                </Container> 
                <Footer navigation={this.props.navigation}/>
            </MainWrapper>
        </ThemeProvider>
      <ContactUsModal
        data-test-id="contactUsModal"
        isOpen={this.state.openModal}
        onClose={() => this.setState({ openModal: false })}
        id="contact modal"
      />
    </>
    );
  }
}

export default JobListing;

const MainWrapper = styled('div')(({ theme }) => ({ 
  '& .listingContainer': {
      marginBottom: '30px', 
      flexDirection: 'unset' as const, 
      justifyContent: 'center'
  },

  '& .hostingOnDayDockerWrapper': {
      width: '100%',
      backgroundImage: `url(${ImgBackground})`,      
      backgroundSize: 'cover',
      backgroundRepeat: 'revert-layer',
      padding: '100px 0 150px 0',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center' as const,
      gap: '50px',
      '@media (min-width: 1600px)': {
        backgroundSize: 'contain',
        backgroundRepeat: 'round',
      },
  },
  '& .hostingOnDayDockerText': {
      margin: '30px 0px'
  },
  '& .abtDayDockerWrapper': {
      minWidth: '245px',
      maxWidth: '329px',
      display: 'grid',
  },
  '& .abtDayDockerImgs': {
      width: '179px',
      height: '172px',
      margin: 'auto',
  },
  '& .btn': {
      width: '176px',
      height: '56px',
      borderRadius: '8px',
  },

  '& .protectionGuaranteeSection': {
      margin: '120px 80px',
  },
  '& .protectionGuaranteeTexts': {
      backgroundColor: '#FDDA96',
      padding: '56px',
      borderRadius: '8px',
  },
  '& .protectionGuaranteeTitle': {
      fontWeight: '700',
      margin: '30px 0px',
  },
  '& .protectionGuaranteeDescription': {
      fontWeight: '400',
      margin: '30px 0px',
  },
  [theme.breakpoints.down('sm')]: {
      '& .protectionGuaranteeSection': {
          margin: '120px 35px',
      },
      '& .protectionGuaranteeTexts': {
          margin: '120px 0px',
      },
      '& .protectionGuaranteeTitle': {
          fontSize: '32px',
      },
      '& .protectionGuaranteeDescription': {
          fontSize: '20px',
      },
  },
}));
// Customizable Area End
