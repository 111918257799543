import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Grid,
  ImageList,
  Box,
  styled,
  ImageListItem,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  Snackbar,
  SnackbarContent
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import DockBookingPreview from "./DockBookingPreview.web";
import Footer from "../../../components/src/Footer";
import ShareModal from "../../../components/src/ShareModal.web";
import Login from "../../../blocks/email-account-login/src/Login.web";

const configJSON = require("./config.js");
const assets = require("./assets");
// Customizable Area End
// Customizable Area Start
export const theme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
    },
    secondary: {
      main: "#374151",
    },
    error: {
      main: "#EF4444",
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: "2rem !important",
        fontFamily: "Josefin Sans !important",
        color: "#0F172A",
      },
      h5: {
        fontSize: "1.5rem !important",
        fontFamily: "Josefin Sans !important",
        color: "#0F172A",
      },
      h6: {
        fontFamily: "Josefin Sans",
        fontSize: "1.2rem",
        fontWeight: 700,
      },
      body1: {
        fontSize: "1rem !important",
        fontFamily: "Outfit !important",
        fontWeight: 400,
        color: "#0F172A",
      },
      body2: {
        fontSize: "0.8rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#0F172A",
      },
      subtitle1: {
        fontFamily: "Outfit",
        fontSize: "14px",
        fontWeight: 400,
        color: "#6B7280",
      },
      subtitle2: {
        fontFamily: "Outfit",
        fontSize: "12px",
        fontWeight: 400,
        color: "#94A3B8",
      },
    },
    MuiImageListItem: {
      root: {
        borderRadius: "12px",
      },
      imgFullWidth: {
        position: "initial",
        transform: "none",
      },
      imgFullHeight: {
        height: "140px",
      },
    },
    MuiInputLabel: {
      root: {
        padding: "16px",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          border: "none !important",
        },
      },
    },
    MuiSelect: {
      select: {
        fontFamily: "Outfit",
        fontSize: "14px",
        fontWeight: 400,
        color: "#64748B",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiMenuItem: {
      root: {
        padding: "10px 20px !important",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        textTransform: "capitalize",
        fontFamily: "Outfit",
        fontSize: "1rem",
        fontWeight: 700,
      },
      contained: {
        margin: "20px 0 0 0",
        padding: "14px 24px",
      },
      containedPrimary: {
        color: "white",
      },
    },
    MuiListItem: {
      gutters: { paddingLeft: 0 },
      root: {
        padding: "2px 0 !important",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "35px",
        color: "#000000",
      },
    },
    MuiListItemText: {
      root: {
        display: "flex",
        flexDirection: "column-reverse",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#CBD5E1",
        margin: "25px 0",
      },
      vertical: {
        margin: "0 30px",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
      },
    },
  },
});
const listingType = (type: string)=> {
  return type === "whole_dock" ? 'Whole dock' : 'Slip only';
} 

const renderFavoriteBtn = (favourite_id: number | null, onClick: () => void) => {
  return (
    <Button
      data-test-id="addToFavBtn"
      variant="outlined"
      color="primary"
      className="favButton"
      onClick={onClick}
    >
      {favourite_id ? (
        <>
          <FavoriteRoundedIcon
            color="error"
            fontSize="small"
            className="svgIcon"
          />
          <div className="favText">{configJSON.addedToFavBtnText}</div>
        </>
      ) : (
        <>
          <FavoriteBorderRoundedIcon
            fontSize="small"
            className="svgIcon"
          />
          <div className="favText">{configJSON.addToFavBtnText}</div>
        </>
      )}
    </Button>
  )
};
// Customizable Area End

import DockBookingController, {
  BookingPage,
  Props,
} from "./DockBookingController.web";

export default class DockBooking extends DockBookingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { bookingPage, selectedDetails, dockBookingData, dateStrings, total_reviews, overall_rating, shareFlag } = this.state;    

    const dock = dockBookingData.attributes     
        
    return (
      <ThemeProvider theme={theme}>
        <ShareModal
          isOpen={shareFlag}
          link={`https://nathanrobinsonsbc-496588-react.b496588.dev.eastus.az.svc.builder.cafe/dock-booking/${this.state.dockBookingData.attributes.dock_listing_id}?booking=confirm&url=${this.state.shareUrl}`}
          onOpenCloseModal={this.handleOpenShareModal}
          handleCopyText={this.handleCopyText}
        />
        <Login
          data-test-id="login-modal"
          isOpen={this.state.loginModal}
          id="login modal"
          navigation={this.props.navigation}
          onClose={() => {
            this.setState({ loginModal: false });
          }}
        />
          <Container maxWidth="lg">
            {bookingPage === BookingPage.Booking ? (
              <Wrapper>
                <Snackbar
                  data-test-id="errorSnackbar"
                  open={this.state.errorResMsg}
                  autoHideDuration={3000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={() => this.setState({ errorResMsg: false })}
                >
                  <SnackbarContent
                    message="Dock already reserved for selected dates!."
                    style={{
                      backgroundColor: '#FBBF24',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  />
                </Snackbar>
                <Header navigation={this.props.navigation} />
                <Box className="headerTitle">
                  <Typography
                    variant="h1"
                    className="fontWeight700"
                    data-test-id="titleText"
                  >
                    {dock.listing_title}
                  </Typography>
                  <Box style={{display: "flex", gap: "10px", alignItems: "center"}}>
                    {this.state.timerFlag && (
                      <Typography
                        variant="h6"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "rgb(221, 221, 90)",
                        }}
                      >
                        <img
                          src={assets.timeIcon}
                          style={{ width: "25px", height: "auto" }}
                          loading="lazy"
                        />
                        {this.formatTime(this.state.timer)}
                      </Typography>
                    )}
                    {renderFavoriteBtn(dock.favourite_id, () => this.addRemoveFromFavorites(dock.favourite_id))}
                  </Box>
                </Box>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Box className="imageContainer">
                      <img
                        src={dock.images?.[0] || assets.calendarDetailIcon}
                        alt="Lake Austin, Texas"
                        className="bigImage"
                        loading="lazy"
                      />
                      <ImageList
                        cols={4}
                        rowHeight={150}
                        component={"ul"}
                        className="imageListContainer"
                      >
                        {dock.images?.slice(1).map((item) => (
                          <ImageListItem key={item} className="imageListItem">
                            <img
                              src={item}
                              alt={dock.listing_title}
                              height={140}
                              className="smallImage"
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Box>
                    <Box className="dockDetailsContainer">
                      <Typography variant="h5" className="fontWeight700">
                        {dock.listing_title}
                      </Typography>
                      <Typography variant="body1" className="paddingTop5">
                        {dock.about}
                      </Typography>
                      <Typography
                        variant="body1"
                        className="ratingText fontWeight700"
                      >
                        <StarBorderRoundedIcon className="ratingIcon" />
                        {(dock.host.rating || 0).toFixed(1)}
                        <FiberManualRecordIcon
                          style={{
                            fontSize: "4px",
                            color: "#6B7280",
                            padding: "0 0 0 5px",
                            marginRight: '5px'
                          }}
                        />
                        <Typography variant="body1" className="fontWeight700">
                          {dock.host.reviews} reviews
                        </Typography>
                      </Typography>
                      <ListItem>
                        <ListItemAvatar>
                          {this.getHost(dock.host.avatar)}
                        </ListItemAvatar>
                        <ListItemText
                          secondary={configJSON.hostedByText}
                          primary={dock.host.name}
                        />
                      </ListItem>
                    </Box>
                    <Box className="dockPropertyBox">
                      <Box className="flexCol">
                        <Typography variant="body1" className="fontWeight700">
                          {listingType(dock.listing_type)}
                        </Typography>
                        <Typography variant="body1" className="colorGrey">
                          {configJSON.dockTypeText}
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box className="flexCol">
                        <Typography variant="body1" className="fontWeight700">
                          {this.showStateName(dock.administrative_area_level_1, dock.administrative_area_level_2)}
                        </Typography>
                        <Typography variant="body1" className="colorGrey">
                          {configJSON.boatDockingAreaText}
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box className="flexCol">
                        <Typography variant="body1" className="fontWeight700">
                          {dock.water_depth}ft
                        </Typography>
                        <Typography variant="body1" className="colorGrey">
                          {configJSON.waterDepthText}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Box>
                      <Typography variant="h6" className="fontWeight700">
                        {configJSON.dockPricesText}
                      </Typography>
                      <Box className="dockPriceBox">
                        <Box className="dockPropertyBox flexCol">
                          <Typography variant="h6" className="fontWeight700">
                            ${dock.prices.per_day}
                          </Typography>
                          <Typography variant="body1" className="colorGrey">
                            {configJSON.perDayText}
                          </Typography>
                        </Box>
                        <Box className="dockPropertyBox flexCol">
                          <Typography variant="h6" className="fontWeight700">
                            ${dock.prices.per_Week}
                          </Typography>
                          <Typography variant="body1" className="colorGrey">
                            {configJSON.perWeekText}
                          </Typography>
                        </Box>
                        <Box className="dockPropertyBox flexCol">
                          <Typography variant="h6" className="fontWeight700">
                            ${dock.prices.per_month}
                          </Typography>
                          <Typography variant="body1" className="colorGrey">
                            {configJSON.perMonthText}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    {this.renderBookingCard()}
                  </Grid>
                </Grid>
                <Divider />
                <Box>
                  <Typography variant="h6" className="fontWeight700">
                    {configJSON.dockAvailabilityText}
                  </Typography>               
                  <ReactDatePicker
                    data-test-id="availabilityCalendar"
                    className="checkInDatepicker"
                    excludeDates={dateStrings}
                    selectsStart
                    monthsShown={
                      Math.min(this.state.end_date.getMonth() -
                      this.state.start_date.getMonth() +
                      12 *
                        (this.state.end_date.getFullYear() -
                        this.state.start_date.getFullYear()) +
                      1, 3)
                    }
                    minDate={new Date()}
                    showPreviousMonths={false}
                    popperClassName="calendarOpen"
                    calendarClassName="calendar"
                    open={true}
                    onChange={() => {}}
                  />
                </Box>
                <Divider />
                {dock.rental_reason && 
                <Box>
                  <Typography variant="h6" className="fontWeight700">
                    {configJSON.whyThisDockText}
                  </Typography>
                  <Box className="marginTop10">
                      <Typography variant="body1">
                        {this.textToShow(dock.rental_reason)}                      
                      </Typography>
                    {dock.rental_reason.length > 50 && 
                    <Typography
                      variant="body1"
                      className="marginTop10 fontWeight700 textDecorationUnderline"
                      onClick={this.handleToggle}
                      data-test-id='show-reason'
                    >
                      {this.showLessMore()}
                    </Typography>
                    }
                  </Box>
                </Box>
                }
                <Divider />
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      variant="body1"
                      className="fontWeight700 subHeader"
                    >
                      {configJSON.amenitiesText}
                    </Typography>
                    <Box className="marginTop10 gridCol2">
                      {this.renderFeatures()}
                    </Box>
                    {this.showMoreFeatures()}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      variant="body1"
                      className="fontWeight700 subHeader"
                    >
                      {configJSON.addonText}
                    </Typography>
                    <Box className="marginTop10 gridCol2 gridColGap">
                    {this.renderAddons()}
                    </Box>
                    {this.showMoreAddons()}
                  </Grid>
                </Grid>
                <Divider />
                <Box style={{marginBottom:'77px'}}>
                  <Typography variant="h6" className="fontWeight700">
                    {configJSON.thingsToKnowText}
                  </Typography>
                  <Grid container className="paddingTop5" spacing={4}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="body1"
                        className="fontWeight700 subHeader"
                      >
                        {configJSON.dockRulesText}
                      </Typography>
                      <List>{this.renderRules()}</List>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>                    
                      <List>{this.renderInstructions()}</List>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="body1"
                        className="fontWeight700 subHeader"
                      >
                        {configJSON.cancellationPolicyText}
                      </Typography>
                      <Typography
                        variant="body1"
                        className="marginTop10"
                      >
                        {`Boaters will only be entitled to a refund if the cancellation is at least ${dock.cancellation_policy.replace('_', ' ')} before the reservation start time. (excluding service fee)`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body1"
                    className="marginTop10 fontWeight700 textDecorationUnderline"
                    onClick={this.handleRules}
                    data-test-id='show-rules'
                  >
                    {this.showLessMoreRules()}
                  </Typography>
                  <Divider />
                  <Typography variant="h6" className="fontWeight700">
                    Reviews
                  </Typography>
                  {this.state.reviews?.length > 0 ? 
                  <>
                  <Typography
                    variant="body1"
                    style={{marginBottom:'24px'}}
                    className="ratingText fontWeight700"
                  >
                    <StarBorderRoundedIcon className="ratingIcon" />
                    {(overall_rating).toFixed(1)}
                    <FiberManualRecordIcon
                      style={{
                        fontSize: "4px",
                        color: "#6B7280",
                        padding: "0 0 0 5px",
                        marginRight: '5px'
                      }}
                    />
                    <Typography variant="body1" className="fontWeight700">
                      {total_reviews} reviews
                    </Typography>
                  </Typography>
                  <Grid container className="paddingTop5" spacing={4}>
                    {this.renderReviews()}
                    </Grid> 
                    {this.state.reviews.length > 4 && 
                    <Typography
                    variant="body1"
                    className="fontWeight700 textDecorationUnderline"
                    style={{marginTop: '24px', }}
                    data-test-id='show-reviews'
                    onClick={()=> this.setState({showAllReviews : !this.state.showAllReviews})}
                  >
                    {this.showLessMoreReviews()}
                  </Typography>  
                  }          
                  </>
                  :
                  <Typography variant="body1" color="textSecondary" style={{margin: '10px 0 30px 0'}}>
                    No Dock Reviews are available
                  </Typography>
                  }           
                </Box>
              </Wrapper>
            ) : (
              <DockBookingPreview
                bookingPage={bookingPage}
                selectedDetails={selectedDetails}
                dockDetails={dockBookingData.attributes}
                onBackBtnClick={this.handleBackBtnClick}
                onSuccessCall={this.goToNextPage}
                handleOpenShareModal={this.handleOpenShareModal}
                timer={this.formatTime(this.state.timer)}
                kycModal={this.state.kycModal}
                navigation={this.props.navigation}
                id="DockBookingPreview"
              />
            )}
          </Container>
          {bookingPage === BookingPage.Booking ? (
          <Footer navigation={this.props.navigation}/>):""}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const Wrapper = styled("div")({
  "& .fontWeight600": {
    fontWeight: "600 !important",
    fontFamily:'Outfit',
    fontSize:'16px'
  },
  "& .fontWeight700": {
    fontWeight: "700 !important",
  },
  "& .pointer": {
    cursor: "pointer",
  },
  "& .paddingTop5": {
    paddingTop: "5px",
  },
  "& .flexItems": {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  "& .gapNone": {
    gap: 0,
  },
  "& .justifySpaceBetween": {
    justifyContent: "space-between",
  },
  "& .textDecoration": {
    display: "flex",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "underline",
  },
  "& .colorGrey": {
    color: "#64748B",
  },
  "& .marginTop10": {
    marginTop: "10px",
  },
  "& .textDecorationUnderline": {
    textDecoration: "underline",
    cursor: "pointer",
  },
  "& .gridCol2": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "8px",
  },
  "& .gridColGap": {
    gridColumnGap: 70,
  },
  "& .flexCol": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    alignItems: "center",
    textAlign: "center",
  },
  "& .svgIcon": {
    marginRight: "8px",
  },
  "& .headerTitle": {
    margin: "15px 0 20px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .favButton": {
    margin: 0,
    padding: "6px 16px",
    textTransform: "none",
  },
  "& .imageContainer": {
    display: "flex",
    gap: 10,
  },
  "& .bigImage": {
    borderRadius: "40px",
    height: "84vh",
    maxHeight: "450px",
    minHeight: "450px",
    width: "77%",
  },
  "& .smallImage": {
    borderRadius: "12px",
    width: "100%",
  },
  "& .imageListContainer": {
    display: "flex",
    flexDirection: "column",
    flexWrap: "initial",
    height: "450px",
  },
  "& .imageListItem": {
    padding: "5px 0 !important",
    width: "100% !important",
    height: "auto !important",
  },
  "& .dockDetailsContainer": {
    margin: "25px 0",
  },
  "& .dockPropertyBox": {
    maxWidth: "max-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    padding: "25px 30px",
    border: "1px solid #94A3B8",
    borderRadius: "8px",
  },
  "& .dockPriceBox": {
    margin: "10px 0",
    display: "flex",
    gap: 20,
  },
  "& .borderedBox": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "30px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  "& .priceTag": {
    display: "flex",
    gap: 5,
    alignItems: "center",
  },
  "& .checkInOutContainer": {
    paddingTop: "10px",
    display: "flex",
    height: "auto",
  },
  "& .checkInBox": {
    width: "50%",
    padding: "16px",
    display: "flex",
    flexFlow: "column",
    border: "1px solid #D1D5DB",
    borderWidth: "1px 0 1px 1px",
    borderRadius: "8px 0 0 0",
  },
  "& .checkOutBox": {
    width: "50%",
    padding: "16px",
    display: "flex",
    flexFlow: "column",
    border: "1px solid #D1D5DB",
    borderRadius: "0 8px 0 0",
  },
  "& .selectGuestStyle": {
    minWidth: "100%",
    boxSizing: "border-box",
    padding: "12px 12px 8px 12px",
    border: "1px solid #D1D5DB",
    borderWidth: "0 1px 1px 1px",
    borderRadius: "0 0 8px 8px",
  },
  "& .ratingText": {
    display: "flex",
    gap: 0,
    paddingTop: "15px",
    alignItems: "center",
  },
  "& .ratingIcon": {
    color: "#DE3151",
  },
  "& .reportBox": {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    textAlign: "center",
  },
  "& .subHeader": {
    fontSize: "1.07rem !important",
  },
  "& .calendarOpen": {
    margin: "10px 0",
    position: "relative !important" as any,
    pointerEvents: "none",
    transform: "none !important",
  },
  "& .calendar .react-datepicker__triangle": {
    display: "none",
  },
  "& .calendar .react-datepicker__navigation--previous": {
    display: "none",
  },
  "& .calendar .react-datepicker__navigation--next": {
    display: "none",
  },
  "& .react-datepicker__input-container": {
    display: "none",
  },
  "& .react-datepicker": {
    padding: "0 15px",
  },
  "& .react-datepicker-popper": {
    padding: 0,
  },
  "& .react-datepicker__header": {
    backgroundColor: "transparent",
    border: "none",
  },
  "& .react-datepicker__navigation--previous": {
    margin: "15px 25px",
  },
  "& .react-datepicker__navigation--next": {
    margin: "15px 25px",
  },
  "& .react-datepicker__current-month": {
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#0F172A",
    padding: "10px",
  },
  "& .react-datepicker__day-names": {
    fontFamily: "Outfit",
  },
  "& .react-datepicker__day-name": {
    padding: "0 5px",
    color: "#64748B",
  },
  "& .react-datepicker__day": {
    fontFamily: "Outfit",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#4F9FF873",
      color: "#fff",
      borderRadius: "50%",
    },
  },
  "& .react-datepicker__day--selected": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--in-range": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--in-selecting-range": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--outside-month": {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down(650)]: {
    "& .react-datepicker": {
      display: "grid",
    },
    "& .react-datepicker__navigation--previous": {
      margin: "10px",
    },
    "& .react-datepicker__navigation--next": {
      margin: "10px",
    },
  },
  [theme.breakpoints.down(530)]: {
    "& .svgIcon": {
      marginRight: 0,
      fontSize: "2rem",
    },
    "& .favText": {
      display: "none",
    },
    "& .favButton": {
      border: "none",
    },
  },
  [theme.breakpoints.down(460)]: {
    "& .dockPropertyBox": {
      padding: "10px 15px",
    },
  },
  [theme.breakpoints.down(370)]: {
    "& .dockPriceBox": {
      gap: "10px",
    },
  },
});
// Customizable Area End
