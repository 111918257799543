import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";

interface FaqAttributes {
  id: number | null;
  question: string;
  answer: string;
}

interface TermsConditionData {
  id: number;
  created_at: string; 
  updated_at: string;
  description: string | null;
  terms: string;
  license: string;
  disclaimer: string;
  limitations: string;
  revision_and_errata: string;
  active: boolean;
}

interface PrivacyPolicyData {
  id: number;
  created_at: string; 
  updated_at: string;
  description: string | null;
  active: boolean;
}

interface FaqData {
  id: string;
  type: string;
  attributes: FaqAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openModal: boolean;
    faqsOpen: boolean;
    faqDataList: FaqData[];
    termsConditionList: TermsConditionData;
    privacyPolicyList: PrivacyPolicyData;
    nodata: boolean;
    expandedAccordionPanel: string | boolean;
    updateErrorMsg: string;
    updateSuccessMsg: string;
    loginModal: boolean;
    errorMsg: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class JobListingController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    getFAQCallId: string = "";
    getTermsServiceCallId: string = "";
    updateTermsServiceCallId: string = "";
    getPrivacyPolicyCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        expandedAccordionPanel: false,
        openModal: false,
        faqsOpen: false,
        faqDataList: [],
        nodata: false,
        updateErrorMsg: '',
        updateSuccessMsg: '',
        termsConditionList: {
          id: 0,
          created_at: "",
          updated_at: "",
          description: null,
          terms: "",
          license: "",
          disclaimer: "",
          limitations: "",
          revision_and_errata: "",
          active: true
      },
      privacyPolicyList: {
        id: 0,
        created_at: "",
        updated_at: "",
        description: null,
        active: true
      },
        loginModal: false,
        errorMsg: "",
        // Customizable Area End
      };

      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area Start
      // Customizable Area End
    }

    async componentDidMount() {
      super.componentDidMount();
      // Customizable Area Start
      this.getFAQsData();
      this.getTermsAndConditionData();
      this.getPrivacyPolicyData();
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.apiSuccessCallBackController(apiRequestCallId, responseJson);
      // Customizable Area End
    }

    // Customizable Area Start
    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: Record<string, unknown>
    ) => {
      const successCallbackMap = {
        [this.getFAQCallId]: this.handleGetFAQsAPIResponse,
        [this.getTermsServiceCallId]: this.handleGetTermsServiceAPIResponse,
        [this.updateTermsServiceCallId]: this.handleUpdateTermsServiceAPIResponse,
        [this.getPrivacyPolicyCallId]: this.handleGetPrivacyPolicyAPIResponse,
      };
  
      if (apiRequestCallId) {
        const successCallback: (responseJSON: Record<string, unknown>) => void =
          successCallbackMap[apiRequestCallId];
        !!successCallback && successCallback(responseJSON);
      }
    };

    getFAQsData = async () => {
      this.getFAQCallId = sendAPIRequest(configJSON.faqPath, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.validationApiContentType,
        },
        },
      );
    };
    getTermsAndConditionData = async () => {
      this.getTermsServiceCallId = sendAPIRequest(configJSON.TermsServicePath, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.validationApiContentType,
        },
        },
      );
    };
    updateUserTerms = async () => {
      const token = await getStorageData("token");
      this.updateTermsServiceCallId = sendAPIRequest(configJSON.updateTermsPath, {
        method: configJSON.exampleAPiMethod,
        headers: {
          "Content-Type": configJSON.validationApiContentType,
          "token": token,
        },
        body: {
          term_id: this.state.termsConditionList.id,
          is_accepted: this.state.termsConditionList.active
        }
        },
      );
    };
    handleGetFAQsAPIResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) return;
      const resJson = responseJSON as {
        data: FaqData[];
      };
      if (resJson.data) {
        this.setState({
              faqDataList: resJson.data,
        });
      }
    }

    handleGetTermsServiceAPIResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) return;
      const resJson = responseJSON as {
        data?: TermsConditionData;
      };
      if(!resJson.data) {
        this.setState({
          nodata: true,
        });
      } else {
        this.setState({
          termsConditionList: resJson.data,
        });
      }
    }
    handleUpdateTermsServiceAPIResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) return;
      const resJson = responseJSON as {
        error?: string;
        message?: string;
      };
      if(resJson.error){
        this.setState({
          updateErrorMsg: resJson.error,
        });
      } else if (resJson.message){
        this.setState({
          updateSuccessMsg: resJson.message,
        });
      }
    }

    getPrivacyPolicyData = async () => {
      this.getPrivacyPolicyCallId = sendAPIRequest(configJSON.privacyPolicyPath, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.validationApiContentType,
        },
        },
      );
    };

    handleGetPrivacyPolicyAPIResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) return;
      const resJson = responseJSON as {
        data: PrivacyPolicyData;
      };
      if(resJson.data) {
        this.setState({
          privacyPolicyList: resJson.data,
        });
      }
    };

    handleErrorResponse = (responseJSON: Record<string, unknown>) => {
      const { errors } = responseJSON as { errors: { error: string } };
      if (errors) {
        this.setState({
          errorMsg: errors.error,
        });
        return true;
      }
      return false;
    };

    setExpandedAccordionPanel =
    (panel: string) =>
    (event: React.SyntheticEvent<{}>, isExpanded: boolean) => {
      this.setState({ expandedAccordionPanel: isExpanded ? panel : false });
    };

    handleListYourDockBtnClick = async () => {
        const token = await getStorageData("token");
        if(token){
          this.handleNavigation("DockListingForm");
        }
        else{
          this.setState({ loginModal: true });
        }
      }
      
      handleChange = () => {
        this.setState({ updateErrorMsg: "" });
        this.setState(prevState => ({
          termsConditionList: {
            ...prevState.termsConditionList,
            active: !this.state.termsConditionList.active 
          }
        }));
        this.updateUserTerms();
      }
      handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
      goBack = () => {
        this.props.navigation.goBack();
      }
    // Customizable Area End
  }
