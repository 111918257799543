// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export const assets = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

export interface INotificationSetting {
  allow_push_notifications: boolean;
  allow_emails: boolean;
  allow_text_messages: boolean;
}

interface SProps {
  notificationSettings: INotificationSetting;
  successMsg: string;
}
// Customizable Area End

interface SSProps {
  ssId: string;
}

export default class SettingsNotificationController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  // Customizable Area Start
  getNotificationDetailsApiCallId: string = "";
  updateNotificationDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    // Customizable Area Start
    this.state = {
      notificationSettings: {
        allow_push_notifications: false,
        allow_emails: false,
        allow_text_messages: false,
      } as INotificationSetting,
      successMsg: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.fetchNotificationDetails();
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: INotificationSetting & { meta: string }
  ) => {
    const successCallbackMap = {
      [this.getNotificationDetailsApiCallId]: this.handleGetAPIResponse,
      [this.updateNotificationDetailsApiCallId]: this.handlePatchAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (
        responseJSON: INotificationSetting & { meta: string }
      ) => void = successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  fetchNotificationDetails = async () => {
    const token = await getStorageData("token");
    this.getNotificationDetailsApiCallId = sendAPIRequest(
      "bx_block_settings/notification_settings/view",
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handleNotificationSettingsUpdate = async (
    keyValue: keyof INotificationSetting
  ) => {
    const updatedNotificationSetting = {
      ...this.state.notificationSettings,
      [keyValue]: !this.state.notificationSettings[keyValue],
    };
    const token = await getStorageData("token");
    this.updateNotificationDetailsApiCallId = sendAPIRequest(
      "bx_block_settings/notification_settings/modify",
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: { settings: updatedNotificationSetting },
      }
    );
  };

  handleGetAPIResponse = (response: INotificationSetting) => {
    this.setState({ 
      notificationSettings: response || {
        allow_push_notifications: false,
        allow_emails: false,
        allow_text_messages: false,
      }
    });
  };

  handlePatchAPIResponse = (
    response: INotificationSetting & { meta: string }
  ) => {
    this.setState({
      notificationSettings: response as INotificationSetting,
      successMsg: response.meta,
    });
  };
  // Customizable Area End
}
