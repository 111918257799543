import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { DockDetail } from "../../../blocks/reservations/src/DockBookingController.web";
import { Box, Divider, ListItem, ListItemText, Typography } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { getLastPartOfURL, sendAPIRequest } from "../../../components/src/utils";

const assets = require("./assets");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
      showMoreRules: boolean;
      listedDockDetailsData: DockDetail;
      userDetails: {
        first_name: string;
        last_name: string;
        profile_pic: string
      };
      rulesInstuction: { rules: string[], instructions: string[] };
      errorMsg: string;
      isMoreFeatures: boolean;
      isMoreAddons: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ListingPreviewController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
      getlistedDockDetailsCallId: string = "";
      getUserDetailsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        // Customizable Area Start
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.NavigationPayLoadMessage),
          getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

    this.state = {
      // Customizable Area Start
      showMoreRules: false,
      listedDockDetailsData: {} as DockDetail,
      userDetails: {
        first_name: "",
        last_name: "",
        profile_pic: "",
      },
      rulesInstuction: {
        rules: [
         "Check-in: After 4:00 PM",
         "Checkout:  10:00 AM",
         "Self check-in with lockbox",
         "Not suitable for infants (under 2 years)",
         "No smoking",
         "No pets",
         "No parties or events",
        ],
        instructions: [
          "Committed to enhanced cleaning process. Show more",
          "Daydocker social-distancing and other COVID-19-related guidelines apply",
          "Carbon monoxide alarm",
          "Smoke alarm",
          "Security Deposit - if you damage the dock, you may be charged up to $566",
        ],
      },
      errorMsg: "",
      isMoreFeatures: false,
      isMoreAddons: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async componentDidMount() {
      super.componentDidMount();
      this.getListedDockDetails();
      this.getUserDetails();
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.apiSuccessCallBackController(apiRequestCallId, responseJson);
      // Customizable Area End
    }
    // Customizable Area Start
    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: Record<string, unknown>
    ) => {
      const successCallbackMap = {
        [this.getlistedDockDetailsCallId]:
          this.handleDockDetailsAPIResponse,
          [this.getUserDetailsCallId]: this.handleUserDetailsAPIResponse,
      };
  
      if (apiRequestCallId) {
        const successCallback: (responseJSON: Record<string, unknown>) => void =
          successCallbackMap[apiRequestCallId];
        !!successCallback && successCallback(responseJSON);
      }
    };

    getListedDockDetails = async () => {
      const token = await getStorageData("token");
      const dockId = getLastPartOfURL().url || -1;
      this.getlistedDockDetailsCallId = sendAPIRequest(
        `${configJSON.urlGetDockListingDetails}/${dockId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };

    getUserDetails = async () => {
      const token = await getStorageData("token");
      this.getUserDetailsCallId = sendAPIRequest(
        `/bx_block_settings/profile_settings/view`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };
    handleDockDetailsAPIResponse = (
      responseJSON: Record<string, unknown>
    ) => {
      const resJson = responseJSON as {
        data?: { id: string; type: string; attributes: DockDetail };
        errors?: string[];
      };
      if (resJson.data) {
        const dockDetails = resJson.data?.attributes;
        if(dockDetails) {
          this.setState({
            listedDockDetailsData: dockDetails,
          });
        } else {
          this.setState({ 
            errorMsg: "Dock is not exist", 
            listedDockDetailsData: {
              listing_title: "Not Available",
              about: "Not Available",
              address: "Not Available",
              cancellation_policy: "Not Available",
              dock_add_ons: [],
              features: [],
              images: [],
              guest_total: "0"
            } as unknown as DockDetail 
          });
        }
      } else if(resJson.errors) {
        this.setState({
          errorMsg: resJson.errors[0]
        })
      }
    };

    handleUserDetailsAPIResponse = (
      responseJSON: Record<string, unknown>
    ) => {
      const response = responseJSON as {
        data?: { attributes: {
          first_name: string;
          last_name: string;
          profile_pic: string;
        } }
      };
      if (response.data) {
        this.setState({
          userDetails: {
            first_name: response.data.attributes.first_name,
            last_name: response.data.attributes.last_name,
            profile_pic: response.data.attributes.profile_pic,
          }
        });
      }
    };

    handleMoreRules = () =>{
      this.setState({ showMoreRules: !this.state.showMoreRules})
    };
    showStateName = (dock1:string | null, dock2:string | null) => {
      return dock1 ? (`${dock1}, ${dock2}`) : "Not Provided"
    }
    showMoreLessRules = () => {
      return this.state.showMoreRules ? configJSON.showLessBtn : configJSON.showMoreBtn
    };

    handleBackBtnClick = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "DockListingForm");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    };

    handleMoreFeatures = () =>{
      this.setState({ isMoreFeatures: !this.state.isMoreFeatures})
    };

    handleMoreAddons = () => {
      this.setState({ isMoreAddons: !this.state.isMoreAddons})
    };

    renderPaymentDetailCard = () => {
      return (
        <Box className="borderedBox">
          <Box className="flexGap">
            <Box className="flexItems justifySpaceBetween" style={{marginBottom: "15px"}}>
              <Typography variant="body1" className="colorDarkBlue" style={{ fontSize: "0.9rem" }}>
                {configJSON.basePayPriceText}
              </Typography>
              <Typography variant="body1" className="colorDarkBlue">
                ${this.state.listedDockDetailsData.base_price || 0}
              </Typography>
            </Box>
            <Box className="flexItems justifySpaceBetween">
              <Typography variant="body1" className="colorDarkBlue" style={{ fontSize: "0.9rem" }}>
                {configJSON.daydockerServiceFeeText}
              </Typography>
              <Typography variant="body1" className="colorDarkBlue">
                ${this.state.listedDockDetailsData.service_fee || 0}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ margin: "15px 0" }} />
          <Box className="flexItems justifySpaceBetween">
            <Box className="guestTotalFlex">
              <Typography variant="body1">
                {configJSON.daydockerGuestTotalText}
              </Typography>
              <Typography variant="subtitle1">
                {configJSON.beforeTaxesText}
              </Typography>
            </Box>
            <Typography variant="h6">${this.state.listedDockDetailsData.guest_total}</Typography>
          </Box>
        </Box>
      );
    };

    renderDockRules = () => {
      return (
        <>
          {this.state.rulesInstuction.rules.map((rule, index) => {
          if (index > 3 && !this.state.showMoreRules) return null;
          return (
            <ListItem key={index}>
              <ListItemText primary={rule} />
            </ListItem>
          )}
          )}
        </>
      );
    };

    renderDockInstructions = () => {
      return (
        <>
          {this.state.rulesInstuction.instructions.map((instruction, index) => {
            if (index > 3 && !this.state.showMoreRules) return null;
          return(
            <ListItem key={index}>
              <ListItemText primary={instruction} />
            </ListItem>
          )
          })}
        </>
      );
    };
    // Customizable Area End
  }