// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const leftArrow = require("../assets/leftArrow.png");
export const dummyProduct = require("../assets/dummy-image.jpg");
export const wideBerthImage = require("../assets/wide-birth.jpeg");
export const flounderingImage = require("../assets/floundering.jpeg");
export const evenKeelImage = require("../assets/even-keel.jpeg");
export const smoothSailingImage = require("../assets/smooth-sailing.jpeg");
export const shipShapeImage = require("../assets/ship-shape.jpeg");
export const dayDockerImage = require("../assets/daydocker-logo.jpeg");
export const reviewFooterImage = require("../assets/review-footer.jpeg");

// Customizable Area End
