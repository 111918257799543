Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.paymentMethodsText = "Payment methods";
  exports.paymentMethodInfoText =
    "Add a payment method using our secure payment system, then start planning your boating adventure.";
  exports.hostPaymentMethodsText = "How you’ll get paid";
  exports.hostPaymentMethodInfoText =
    "You need an active account with our secure payment partner, Stripe, to get paid for your bookings. Stripe account setup can be completed in only a few minutes. ";
  exports.addNewCardText = "Add a new card";
  exports.setUpStripAccountText = "Set up a Stripe account";
  exports.editStripAccountText = "Edit a Stripe account";
  exports.redirectingText = "Redirecting...";
  exports.addPaymentMethodBtnText = "Add a payment method";
  exports.removeText = "Remove";
  exports.addingPaymentMethodBtnText = "Processing..."
  exports.cancelBtnText = "Cancel";
  exports.cardNumberPlaceholder = "****";
  exports.expirationDateText = "Expiration date";
  exports.successPaymentMsg = "Congratulations, Your stripe account setup is completed.";
  exports.redirectionMsg = "You will be redirected to host payments in";
  exports.failPaymentMsg = "Something went wrong while setting up your stripe account.";
  exports.tryAgainBtnText = "Try Again";
  // Customizable Area End
  