import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { getLastPartOfURL } from "../../../components/src/utils";

interface DraftDockdata {id:number,reservation_type:string,cancellation_policy:string, lake:{id:number}, images:string[]};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    reservation_type: string;
    cancellation_policy: string;
    imageBoxs: number[];
    updateProImages: (string | null)[];
    selectedProFiles: (File | null)[];
    draftDockData:DraftDockdata;
    uploadImgErrMsg: string; 
    reservationErrMsg: string; 
    cancelPolicyErrMsg:string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DockListingShowOffController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    draftDockAPICallId: string = "";
    updateDockAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.NavigationMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        reservation_type:"",
        cancellation_policy:"",
        imageBoxs: [1,2,3,4],
        updateProImages: [],
        selectedProFiles: [],
        draftDockData:{id:0,reservation_type:'',cancellation_policy:'', lake:{id:0}, images:[]},
        uploadImgErrMsg: '', 
        reservationErrMsg: '', 
        cancelPolicyErrMsg:''
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area Start
      // Customizable Area End
    }

    async componentDidMount() {
      // Customizable Area Start
      this.getDraftDockData()
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      this.draftDockDataApi(message)
      this.updateDockNextApi(message)
      // Customizable Area End
    }

    // Customizable Area Start
    draftDockDataApi = (message:Message)=>{
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiDraftRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const addOnsDraftJsonData = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiDraftRequestCallId === this.draftDockAPICallId) {
          this.setState({
            draftDockData: addOnsDraftJsonData.data.attributes
          })
          const reservation_type = addOnsDraftJsonData.data.attributes?.reservation_type !== null ? addOnsDraftJsonData.data.attributes?.reservation_type : '';
          const cancellation_policy = addOnsDraftJsonData.data.attributes?.cancellation_policy !== null ? addOnsDraftJsonData.data.attributes?.cancellation_policy : '';
          const updateProImages = addOnsDraftJsonData.data.attributes?.images !== null ? addOnsDraftJsonData.data.attributes?.images : "";
          this.setState({reservation_type, cancellation_policy, updateProImages });
        }
      }
    } 
    updateDockNextApi = (message:Message)=>{
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiUpdateRequestId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const dockUpdateJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiUpdateRequestId === this.updateDockAPICallId) {
          if(dockUpdateJson.data){
            this.handleNavigation("DockListingBasePrice")
          }
        }
      }
    }
    getDraftDockData = async () => {
      const token = await getStorageData("token");
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const getValidationsMsgs = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.draftDockAPICallId = getValidationsMsgs.messageId
      getValidationsMsgs.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getDraftDockApiEndPoint)
      getValidationsMsgs.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsgs.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getcompanyApiMethod
      );
      runEngine.sendMessage(getValidationsMsgs.id, getValidationsMsgs);
    } 
    handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
      this.setState({reservation_type:event.target.value})
    } 
    handleCancellationPolicy = (event: React.ChangeEvent<HTMLInputElement>)=>{
      this.setState({cancellation_policy: event.target.value})
    } 
    handleNavigation = (route: string) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), getLastPartOfURL().url);
      this.send(message);
    }; 
    uploadImageFile= (index: number)=>{
      const inputAddPensionTwo = document.createElement('input');
      inputAddPensionTwo.type = 'file';
      inputAddPensionTwo.accept = 'image/png, image/jpeg, image/jpg';
      inputAddPensionTwo.multiple = false;
      inputAddPensionTwo.click();
      inputAddPensionTwo.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
        this.openProfileUploadInSideMain(event, index);
      };
    }  
    openProfileUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event, index: number) => {  
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (!files || files.length === 0) {
        return;
      }
      const file = files[0];
      const base64 = URL.createObjectURL(file);
      this.setState(prevState => {
        const newUpdateProImages = [...prevState.updateProImages];
        const newSelectedProFiles = [...prevState.selectedProFiles];
        newUpdateProImages[index] = base64;
        newSelectedProFiles[index] = file;
        return {
          updateProImages: newUpdateProImages,
          selectedProFiles: newSelectedProFiles,
        };
      });
    }
    removeImage = (index: number) => {
      this.setState(prevState => {
        const newUpdateProImages = [...prevState.updateProImages];
        const newSelectedProFiles = [...prevState.selectedProFiles];
        newUpdateProImages.splice(index, 1);
        newSelectedProFiles.splice(index, 1);
        return {
          updateProImages: newUpdateProImages,
          selectedProFiles: newSelectedProFiles,
        };
      });
    };    
    handleUpdateDockData = async () => {
      let uploadImgErrMsg = '';
      let reservationErrMsg = '';
      let cancelPolicyErrMsg = '';

      if (this.state.selectedProFiles.length < 1 && this.state.updateProImages.length < 1) {
        uploadImgErrMsg = "Please add at least 1 photo.";
      } 
      if (this.state.reservation_type === "") {
        reservationErrMsg = "Please select one method of accepting reservations.";
      } 
      if (this.state.cancellation_policy === "") {
        cancelPolicyErrMsg = "Please select one cancellation policy.";
      } 
      this.setState({ uploadImgErrMsg, reservationErrMsg, cancelPolicyErrMsg });
      if (uploadImgErrMsg || reservationErrMsg || cancelPolicyErrMsg) {
        return;
      }
      this.setState({ uploadImgErrMsg: '', reservationErrMsg: '', cancelPolicyErrMsg:'' });

      const filteredArray = this.state.updateProImages.filter(item => item !== null && !item?.includes('blob:http'));

      const formData = new FormData();
      if(this.state.selectedProFiles.length){
        this.state.selectedProFiles.forEach(file =>{
          if (file) {
            formData.append(`dock_listing[images][]`, file);
          }
        });
      }
      if(filteredArray.length){
        filteredArray.forEach(file =>{
          if (file) {
            formData.append(`dock_listing[existing_images][]`, file);
          }
        });
      }
      formData.append(`dock_listing[reservation_type]`, this.state.reservation_type);
      formData.append(`dock_listing[cancellation_policy]`, this.state.cancellation_policy);
      formData.append(`dock_listing[step]`, '4');
      formData.append(`dock_listing[lake_id]`, this.state.draftDockData.lake.id.toString());

      const token = await getStorageData("token");
      const headers = {
        token: token,
      };
      const getUpdateResMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateDockAPICallId = getUpdateResMsg.messageId
      getUpdateResMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createDockListingApiEndPoint}/${this.state.draftDockData.id}`)
      getUpdateResMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getUpdateResMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      getUpdateResMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      runEngine.sendMessage(getUpdateResMsg.id, getUpdateResMsg);
    };  
    // Customizable Area End
  }
