import { InputAdornment, TextField, styled } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import React, { Component } from "react";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

interface Props {
  size?: "medium" | "small";
  variant?: "outlined" | "filled" | "standard";
  className?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  value: string | number;
  multiline?: boolean;
  startIcon?: SvgIconComponent | string;
  endIcon?: SvgIconComponent;
  error?: boolean;
  errorMsg?: string;
  isCvv?: boolean;
  maxLength?: number;
  isVisibilityIcon?: boolean;
  InputProps?: any;
  onKeyDown?: () => void;
  isDisable?: boolean;
}

interface S {
  isVisible: boolean;
}

export default class CustomInput extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  formatCvv = (value: string) => {
    let formattedValue: any = value.replace(/\D/g, "");
    if (formattedValue.length > 0) {
      formattedValue = formattedValue
        ?.toString()
        ?.match(new RegExp(".{1,3}", "g"))
        .join("");
    }
    return formattedValue;
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, isCvv } = this.props;
    if (onChange) {
      let formattedValue = event.target.value;
      if (isCvv) {
        formattedValue = this.formatCvv(event.target.value);
      }
      onChange({
        ...event,
        target: {
          ...event.target,
          value: formattedValue,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  onEndIconClick = () => {
    if (this.props.isVisibilityIcon) {
      this.setState((prevState) => {
        return { isVisible: !prevState.isVisible };
      });
    }
  };

  render() {
    const {
      size = "medium",
      placeholder,
      onClick,
      variant = "outlined",
      className = "",
      multiline = false,
      startIcon: StartIcon,
      endIcon: EndIcon,
      error = false,
      errorMsg = "",
      value,
      maxLength,
      isVisibilityIcon = false,
      InputProps,
      onKeyDown,
      isDisable = false,
    } = this.props;

    return (
      <Wrapper>
        <TextField
          variant={variant}
          size={size}
          multiline={multiline}
          value={value}
          type={isVisibilityIcon && !this.state.isVisible ? "password" : "text"}
          minRows={multiline ? 4 : 1}
          placeholder={placeholder}
          className={`customInput ${className}`}
          onChange={this.handleInputChange}
          onClick={onClick}
          InputProps={{
            startAdornment: StartIcon ? (
              <InputAdornment position="start">
                {typeof StartIcon === "string" ? (
                  StartIcon
                ) : (
                  <StartIcon className="adornmentColor" />
                )}
              </InputAdornment>
            ) : null,
            endAdornment: EndIcon ? (
              <InputAdornment position="end">
                <EndIcon className="adornmentColor" />
              </InputAdornment>
            ) : isVisibilityIcon ? (
              <InputAdornment position="end" onClick={this.onEndIconClick}>
                {this.state.isVisible ? (
                  <VisibilityOffOutlinedIcon className="adornmentColor" />
                ) : (
                  <VisibilityOutlinedIcon className="adornmentColor" />
                )}
              </InputAdornment>
            ) : null,
            ...InputProps,
          }}
          inputProps={{ maxLength }}
          error={error}
          helperText={error ? errorMsg : ""}
          onKeyDown={(event) => {
            if (event.keyCode == 13) {
              onKeyDown && onKeyDown();
            }
          }}
          disabled={isDisable}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled("div")(({}) => ({
  "& .MuiInputBase-input::placeholder": {
    fontFamily: "Outfit",
    opacity: 1,
    color: "#94A3B8",
    fontWeight: 400,
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#2684FF",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    fontFamily: "Outfit",
  },
  "& .customInput": {
    width: "100%",
    margin: "5px 0",
  },
  "& .adornmentColor": {
    color: "#64748B",
    cursor: "pointer",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "red",
    marginLeft: 0,
    fontFamily: "Outfit",
  },
}));
