import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import {
  Box,
  Typography as TypoGraphy,
  Container,
  Grid,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
  styled,
  Divider,
} from "@material-ui/core";
import Header from "../../../components/src/Header";
import { getLastPartOfURL } from "../../../components/src/utils";
import Footer from "../../../components/src/Footer";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
    },
  },
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
      },
      h5: {
        fontFamily: "Josefin Sans",
        fontSize: "1.2rem",
        fontWeight: 700,
        color: "#0F172A",
        marginBottom: 10,
      },
      body1: {
        fontFamily: "Outfit",
        fontSize: "1rem",
        fontWeight: 400,
        color: "#0F172A",
      },
      subtitle1: {
        fontFamily: "Outfit",
        fontSize: "0.9rem",
        fontWeight: 400,
        color: "#64748B",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        padding: "5px 32px",
        margin: "20px 0 30px 0",
        textTransform: "capitalize",
        fontFamily: "Outfit",
        fontSize: "1rem",
      },
      label: {
        fontWeight: 700,
      },
      outlined: {
        padding: "5px 32px",
      },
      containedPrimary: {
        color: "white",
      },
    },
    MuiTabs: {
      indicator: {
        display: "none",
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          fontWeight: "700 !important",
          borderLeft: "2px solid #4F9FF8",
        },
        padding: "0 8px",
        margin: 8,
        minHeight: "auto",
      },
      wrapper: {
        alignItems: "flex-start",
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiDivider: {
      root: {
        margin: "10px 0",
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "fill",
      },
    },
  },
});

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SettingWrapper data-test-id="settingWrapper">
          <Container maxWidth="lg">
            <Header
              navigation={this.props.navigation} 
              role= {this.state.userRole === 'host' ? 'host' : 'boater'} 
            />
            <Box className="inboxHeader">
              <TypoGraphy variant="h4" data-test-id="titleText">
                Settings
              </TypoGraphy>
            </Box>
            <Grid container className="viewBlock">
              <Grid item md={2} xs={2} sm={2}>
                <Tabs
                  data-test-id="settingTabs"
                  orientation="vertical"
                  indicatorColor="primary"
                  value={getLastPartOfURL().url}
                  onChange={(event, value) => this.handleSettingChange(value)}
                >
                  {this.state.settingMenu.map((settingItem) => (
                    <Tab
                      key={settingItem.value}
                      value={settingItem.value}
                      icon={<settingItem.icon />}
                      className="tabIcon"
                    />
                  ))}
                  {this.state.settingMenu.map((settingItem) => (
                    <Tab
                      key={settingItem.value}
                      value={settingItem.value}
                      label={settingItem.label}
                      className="tabText"
                    />
                  ))}
                  <Divider />
                  <Tab
                    data-test-id="logoutIcon"
                    value="Logout"
                    icon={<ExitToAppRoundedIcon />}
                    className="tabIcon colorRed"
                    onClick={() => this.handleSettingChange("LandingPage")}
                  />
                  <Tab
                    data-test-id="logoutText"
                    value="Logout"
                    label="Log out"
                    className="tabText colorRed"
                    onClick={() => this.handleSettingChange("LandingPage")}
                  />
                </Tabs>
              </Grid>
              <Grid item md={10} xs={10} sm={10}>
                {this.props.children}
              </Grid>
            </Grid>
          </Container>
          <Footer navigation={this.props.navigation}/>
        </SettingWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const SettingWrapper = styled("div")(({ theme }) => ({
  "& .inboxHeader": {
    margin: "20px 0",
  },
  "& .viewBlock": {
    margin: "30px 0",
  },
  "& .tabText": {
    textTransform: "capitalize",
    fontFamily: "Outfit",
    fontSize: "1rem",
    fontWeight: 400,
    color: "#0F172A",
  },
  "& .colorRed": {
    color: "#EF4444",
  },
  "& .tabIcon": {
    display: "none",
  },
  [theme.breakpoints.down(740)]: {
    "& .tabIcon": {
      display: "flex",
    },
    "& .tabText": {
      display: "none",
    },
  },
}));

// Customizable Area End
