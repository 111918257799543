// Customizable Area Start

import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Header from "../../../components/src/Header";
import CustomInput from "../../../components/src/CustomInput.web";
import GenericModal from "../../../components/src/GenericModal";

import ReviewPromptController, {
  configJSON,
  Props,
} from "./ReviewPromptController";

const assets = require("./assets");

const theme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
    },
  },
  typography: {
    h4: {
      fontSize: "36px !important",
      fontFamily: "Josefin Sans !important",
      fontWeight: "700 !important",
      color: "#0F172A !important",
    },
    h6: {
      fontSize: "24px !important",
      fontFamily: "Josefin Sans !important",
      fontWeight: "400 !important",
      color: "#0F172A !important",
    },
    body1: {
      fontSize: "18px !important",
      fontFamily: "Outfit !important",
      fontWeight: "700 !important",
      color: "#0F172A !important",
    },
    subtitle1: {
      fontSize: "14px !important",
      fontFamily: "Outfit !important",
      fontWeight: "400",
      color: "#64748B",
      lineHeight: "initial !important",
    },
  },
  components: {
    MuiFormGroup: {
      styleOverrides: {
        root: {
          margin: "50px 0",
          alignItems: "baseline",
          justifyContent: "center",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          marginTop: "12px !important",
          fontSize: "14px !important",
          fontFamily: "Outfit !important",
          fontWeight: 700,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px !important",
          padding: "16px 32px !important",
          fontFamily: "Outfit !important",
          fontSize: "1rem !important",
          fontWeight: "700 !important",
        },
        containedPrimary: {
          color: "white !important",
        },
      },
    },
  },
});

export default class ReviewPrompt extends ReviewPromptController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      reviewStars,
      selectedReview,
      reviewText,
      isSubmitted,
      errorMsg,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper data-test-id="reviewWrapper">
          <Snackbar
            data-test-id="errorSnackbar"
            open={errorMsg.length > 0}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => this.setState({ errorMsg: "" })}
          >
            <SnackbarContent
              message={errorMsg}
              style={{
                backgroundColor: "#DC2626",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Snackbar>
          <GenericModal
            data-test-id="lakeModal"
            isCloseIcon={false}
            open={isSubmitted}
            customStyles={{
              modal: {
                marginTop: "-50px",
                padding: "32px 0 0 0",
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <Wrapper>
                <Box className="paddingAuto">
                  <Typography variant="h6" className="fontWeight700">
                    {configJSON.reviewModalTitle}
                  </Typography>
                  <Typography variant="subtitle1">
                    {configJSON.reviewModalInfo}
                  </Typography>
                  <Box className="dayDockerMain">
                    <img
                      src={assets.dayDockerImage}
                      alt="DayDocker"
                      className="dayDockerLogo"
                    />
                  </Box>
                </Box>
                <img src={assets.reviewFooterImage} className="footerImg" />
              </Wrapper>
            </ThemeProvider>
          </GenericModal>
          <Container maxWidth="lg">
            <Header navigation={this.props.navigation} />
            <Box style={{ marginBottom: "100px" }}>
              <Box className="reviewHeader">
                <Typography variant="h4">
                  {configJSON.ahoyDaydockerText}
                </Typography>
                <Box className="subHeader">
                  <Typography variant="h6">
                    {configJSON.experienceQuestionText}
                  </Typography>
                  <Typography variant="h6">
                    {configJSON.rateDockText}
                  </Typography>
                </Box>
              </Box>
              <RadioGroup row value={selectedReview}>
                {reviewStars.map((review, index) => (
                  <Box key={review.label} className="ratingBox">
                    <FormControlLabel
                      data-test-id="ratingStar"
                      value={review.value}
                      control={
                        <Box
                          className="radioControlBox"
                          onClick={() => this.handleReviewChange(review.value)}
                        >
                          <img
                            src={review.image}
                            alt={review.label}
                            width="72px"
                            height="72px"
                          />
                          <Box
                            className="ratingCount"
                            style={{
                              borderColor:
                                review.value === selectedReview
                                  ? "#4F9FF8"
                                  : "#CBD5E1",
                              backgroundColor:
                                review.value === selectedReview
                                  ? "#4F9FF8"
                                  : "transparent",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontWeight:
                                  review.value === selectedReview ? 700 : 400,
                                color:
                                  review.value === selectedReview
                                    ? "#ffffff"
                                    : "#475569",
                              }}
                            >
                              {review.value}
                            </Typography>
                          </Box>
                        </Box>
                      }
                      labelPlacement="bottom"
                      label={review.label}
                      onChange={() => this.handleReviewChange(review.value)}
                    />
                    {index === 0 && (
                      <Typography variant="subtitle1">
                        {configJSON.notLikelyText}
                      </Typography>
                    )}
                    {index === reviewStars.length - 1 && (
                      <Typography variant="subtitle1">
                        {configJSON.extremeLikelyText}
                      </Typography>
                    )}
                  </Box>
                ))}
              </RadioGroup>
              <Box className="reviewInputBox">
                <Typography variant="body1">
                  {configJSON.tellUsMoreText}
                </Typography>
                <CustomInput
                  data-test-id="reviewInput"
                  className="reviewInput"
                  placeholder={configJSON.reviewPlaceholder}
                  value={reviewText}
                  multiline
                  onChange={this.handleReview}
                />
              </Box>
              <Box className="submitBtn">
                <Button
                  data-test-id="submitBtn"
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmitReview}
                >
                  {configJSON.submitBtn}
                </Button>
              </Box>
            </Box>
          </Container>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const Wrapper = styled("div")(() => ({
  "& .fontWeight700": {
    fontWeight: "700 !important",
  },
  "& .reviewHeader": {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  "& .subHeader": {
    textAlign: "center",
  },
  "& .ratingBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
  },
  "& .ratingCount": {
    border: "1px solid",
    borderRadius: "8px",
    padding: "6px 12px",
    cursor: "pointer",
  },
  "& .radioControlBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "25px",
  },
  "& .reviewInputBox": {
    textAlign: "center",
    margin: "10px 0 20px 0",
  },
  "& .reviewInput": {
    maxWidth: "500px",
    margin: "10px 0 !important",
  },
  "& .submitBtn": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .paddingAuto": {
    padding: "0 32px",
  },
  "& .dayDockerMain": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .dayDockerLogo": {
    width: "145px",
    margin: "20px 0 0 10px",
  },
  "& .footerImg": {
    width: "100%",
  },
}));

// Customizable Area End
