import React from "react";
// Customizable Area Start
import { Box, Typography, ThemeProvider, Button, createTheme, styled } from "@material-ui/core";
import KnowYourCustomerKycVerificationController, {
    Props,
} from "./KnowYourCustomerKycVerificationController";
import GenericModal from "../../../components/src/GenericModal";

const configJSON = require("./config.js");

const modalTheme = createTheme({
    palette: {
      primary: {
        main: "#4F9FF8",
      },
    },
    overrides: {
      MuiTypography: {
        h6: {
          fontWeight: 700,
          fontSize: "1.5rem",
          fontFamily: "Josefin Sans",
          color: "#0F172A",
        },
        subtitle1: {
          fontSize: "14px",
          fontFamily: "Outfit",
          fontWeight: 400,
          color: "#64748B",
        },
      },
      MuiButton: {
        root: {
          borderRadius: "8px",
          fontFamily: "Outfit",
          fontSize: "16px",
          fontWeight: 700,
          textTransform: "capitalize",
        },
        containedPrimary: {
          color: "#FFFFFF",
        },
      },
    },
  });
// Customizable Area End




export default class KnowYourCustomerKycVerificationAccount extends KnowYourCustomerKycVerificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Star
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
          <GenericModal
            data-test-id="kycModal"
            isCloseIcon={false}
            open={this.props.isModalOpen}
            customStyles={{
              modal: {
                marginTop: "-50px",
              },
            }}
          >
            <ThemeProvider theme={modalTheme}>
              <Wrapper>
                <Box>
                  <Typography variant="h6" className="fontWeight700">
                    {configJSON.kycModalTitle}
                  </Typography>
                  <Typography variant="subtitle1">
                    {configJSON.kycModalInfo}
                  </Typography>
                  <Button
                    data-test-id="redirectKycBtn"
                    variant="contained"
                    color="primary"
                    style={{ padding: "12px 0", marginTop: "30px", width: "100%" }}
                    onClick={this.redirectToKYC}
                  >
                    {configJSON.kycModalBtn}
                  </Button>
                </Box>
              </Wrapper>
            </ThemeProvider>
          </GenericModal>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled("div")({
  "& .fontWeight700": {
    fontWeight: "700 !important",
  },
  "& .colorGrey": {
    color: "#64748B",
  },
  "& .verificationBox": {
    display: "flex",
    gap: 5,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0 70px 0",
  },
});
// Customizable Area End
