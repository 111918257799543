// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");
export const assets = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

export interface AttributeItem {
  id: number;
  attributes: {
    name: string;
    price: number;
    description: string;
    images: [
      {
        url: string;
      }
    ];
  };
}

export enum ReviewSelection {
  Null,
  WideBerth,
  Floundering,
  EvenKeel,
  SmoohSailing,
  ShipShape,
}

interface Review {
  label: string;
  value: ReviewSelection;
  image: string;
}

interface S {
  isLoadingReviewPrompt: boolean;
  token: string;
  message: string;
  poc_service_id: string;
  name: string;
  email: string;
  comment: string;
  rating: number;
  product_id: number;
  order_id: number;
  selectedTab: number;
  firstName: string;
  lastName: string;
  signupEmail: string;
  isPopupOpen: boolean;
  isModalVisible: false;
  quantity: number;
  productReviewId: number;
  productReview: [];
  productData: {
    product_id: number;
    name: string;
    description: string;
    price: number;
    sourceImage: string;
  }[];
  registrationSuccess: boolean;
  starCount: number;
  productDetails: [];
  customerOrders: {};
  isOrderDetailsModalVisible: boolean;
  reviewStars: Review[];
  selectedReview: ReviewSelection;
  reviewText: string;
  isSubmitted: boolean;
  errorMsg: string;
}

interface SS {
  id: number;
}

export default class ReviewPromptController extends BlockComponent<
  Props,
  S,
  SS
> {
  submitReviewApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      isLoadingReviewPrompt: false,
      token: "",
      message: "",
      poc_service_id: "",
      name: "",
      email: "",
      comment: "",
      rating: 1,
      product_id: 21,
      order_id: 5,
      selectedTab: 0,
      firstName: "",
      lastName: "",
      signupEmail: "",
      isPopupOpen: false,
      isModalVisible: false,
      starCount: 0,
      isOrderDetailsModalVisible: false,
      productData: [],
      productDetails: [],
      productReview: [],
      productReviewId: 0,
      quantity: 0,
      customerOrders: [],
      registrationSuccess: false,
      reviewStars: [
        {
          value: ReviewSelection.WideBerth,
          label: configJSON.wideBerthText,
          image: assets.wideBerthImage,
        },
        {
          value: ReviewSelection.Floundering,
          label: configJSON.flounderingText,
          image: assets.flounderingImage,
        },
        {
          value: ReviewSelection.EvenKeel,
          label: configJSON.evenKeelText,
          image: assets.evenKeelImage,
        },
        {
          value: ReviewSelection.SmoohSailing,
          label: configJSON.smoothSailingText,
          image: assets.smoothSailingImage,
        },
        {
          value: ReviewSelection.ShipShape,
          label: configJSON.shipShapeText,
          image: assets.shipShapeImage,
        },
      ],
      selectedReview: ReviewSelection.Null,
      reviewText: "",
      isSubmitted: false,
      errorMsg: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  datas = async (item: AttributeItem) => {};

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJson);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.submitReviewApiCallId]: this.handleSubmitReviewAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  RedirectScreen = () => {};

  RedirectMyOrder = () => {};

  RedirectSignUpScreen = () => {};

  RedirectLogin = () => {};

  LoginReview = () => {};

  onEmailChangeApp = () => {};

  handleFirstNameApp = () => {};

  handleLastNameApp = () => {};

  handleSignupEmailApp = () => {};

  handleReviewChange = (selectedReview: ReviewSelection) => {
    this.setState({ selectedReview });
  };

  handleReview = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ reviewText: event.target.value.trim() });
  };

  handleSubmitReview = async () => {
    const token = await getStorageData("token");

    if (this.state.reviewText.length === 0) {
      return;
    }

    this.submitReviewApiCallId = sendAPIRequest(
      `bx_block_reviewprompt/review`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: {
          data: {
            review: this.state.selectedReview,
            feedback: this.state.reviewText,
          },
        },
      }
    );
  };

  handleSubmitReviewAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: {
        id: string;
        type: string;
        attributes: object;
      };
      error: string;
    };
    if (response.data) {
      this.setState({ isSubmitted: true });
    } else if (response.error) {
      this.setState({ errorMsg: response.error });
    }
  };
}
// Customizable Area End
