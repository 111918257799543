// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import { Field, Form, Formik } from "formik";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CustomInput from "../../../components/src/CustomInput.web";
import PasswordCriterion from "../../../components/src/PasswordCriterion";
import * as Yup from "yup";

export const configJSON = require("./config");
export const assets = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

interface IPasswordInputs {
  current_password: string;
  password: string;
  password_confirmation: string;
}

interface SProps {
  isChangePasswordFlag: boolean;
  confirmationFlag: boolean;
  passwordInputs: IPasswordInputs;
  lastUpdatedText: string;
  confirmationError: string;
  changePasswordError: string;
  successMsg: string;
}
// Customizable Area End

interface SSProps {
  ssId: string;
}

export default class SettingsLoginSecurityController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  // Customizable Area Start
  getUserDetailsApiCallId: string = "";
  updatePasswordApiCallId: string = "";
  deactivateUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    // Customizable Area Start
    this.state = {
      isChangePasswordFlag: false,
      confirmationFlag: false,
      passwordInputs: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      lastUpdatedText: "",
      confirmationError: "",
      changePasswordError: "",
      successMsg: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  validationSchema = Yup.object({
    current_password: Yup.string().required("Current password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Confirm password is required"),
  });

  async componentDidMount() {
    super.componentDidMount();
    this.fetchUserDetails();
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getUserDetailsApiCallId]: this.handleAPIResponse,
      [this.updatePasswordApiCallId]: this.handlePatchAPIResponse,
      [this.deactivateUserApiCallId]: this.handleDeleteAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  fetchUserDetails = async () => {
    const token = await getStorageData("token");
    this.getUserDetailsApiCallId = sendAPIRequest(
      "bx_block_settings/profile_settings/view",
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handlePasswordUpdate = async (values: IPasswordInputs) => {
    const token = await getStorageData("token");
    this.updatePasswordApiCallId = sendAPIRequest(
      "bx_block_settings/profile_settings/change_password",
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: { settings: values },
      }
    );
  };

  deactiveUserAccount = async () => {
    const token = await getStorageData("token");
    this.deactivateUserApiCallId = sendAPIRequest(
      "bx_block_settings/profile_settings/deactivate",
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handleAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: { attributes: { password_updated_at: string } };
      errors?: Record<string, string | Record<string, string>>[];
    };
    if (response.data) {
      this.setState({
        lastUpdatedText: response.data.attributes.password_updated_at,
      });
    } else if (response.errors) {
      this.setState({
        confirmationError: "Something went wrong while fetching profile details",
      });
    }
  };

  handlePatchAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      message?: string;
      errors?: { current_password: string }[];
    };
    if (response.message) {
      this.setState({
        isChangePasswordFlag: false,
        passwordInputs: {
          current_password: "",
          password: "",
          password_confirmation: "",
        },
        successMsg: response.message,
      });
      this.fetchUserDetails();
    } else if (response.errors?.[0]) {
      this.setState({
        changePasswordError: response.errors[0].current_password,
      });
    }
  };

  handleDeleteAPIResponse = async (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      message?: string;
      errors?: string[];
    };
    if (response.message) {
      this.setState({
        confirmationFlag: false,
        successMsg: response.message,
      });
      await removeStorageData("token");
      await removeStorageData("loginId");
      await removeStorageData("propsData");
      await removeStorageData("selectedLake");
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    } else if (response.errors?.length) {
      this.setState({
        confirmationError: response.errors[0],
      });
    }
  };

  handleOpenClosePasswordForm = () => {
    this.setState((prevState) => {
      return {
        isChangePasswordFlag: !prevState.isChangePasswordFlag,
        successMsg: "",
      };
    });
  };

  handleConfirmationModal = (confirmationFlag: boolean) => {
    this.setState({ confirmationFlag });
  };

  metMin8Characters = () => {
    return this.state.passwordInputs.password.trim().length >= 8;
  };

  metAtLeastOneNumber = () => {
    return /\d/.test(this.state.passwordInputs.password.trim());
  };

  metAtLeastOneSpecialCharacter = () => {
    return /[^A-Za-z0-9]/.test(this.state.passwordInputs.password.trim());
  };

  renderChangePasswordForm = () => {
    return (
      <Formik
        data-test-id="changePasswordForm"
        initialValues={this.state.passwordInputs}
        validationSchema={this.validationSchema}
        onSubmit={(values) => {
          this.handlePasswordUpdate(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          isValid,
          dirty,
          setFieldTouched,
          validateForm,
        }) => (
          <Form>
            <Grid container>
              <Grid item md={12} xs={12} sm={12} className="editViewBlock">
                <Typography
                  variant="body1"
                  className="fontWeight600 colorGrey marginTop10"
                >
                  {configJSON.currentPasswordText}
                </Typography>
                <Field
                  data-test-id="current_password"
                  name="current_password"
                  as={CustomInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("current_password", event.target.value);
                    this.setState((prevState) => {
                      return {
                        changePasswordError: "",
                        passwordInputs: {
                          ...prevState.passwordInputs,
                          current_password: event.target.value,
                        },
                      };
                    });
                  }}
                  value={values.current_password}
                  error={Boolean(
                    (touched.current_password && errors.current_password) ||
                      this.state.changePasswordError.length
                  )}
                  errorMsg={
                    errors.current_password ?? this.state.changePasswordError
                  }
                  isVisibilityIcon
                />
                <Typography
                  variant="body1"
                  className="fontWeight600 colorGrey marginTop10"
                >
                  {configJSON.newPasswordText}
                </Typography>
                <Field
                  data-test-id="password"
                  name="password"
                  as={CustomInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("password", event.target.value);
                    setFieldTouched("password", true);
                    validateForm();
                    this.setState((prevState) => {
                      return {
                        passwordInputs: {
                          ...prevState.passwordInputs,
                          password: event.target.value,
                        },
                      };
                    });
                  }}
                  value={values.password}
                  isVisibilityIcon
                />
                {this.state.passwordInputs.password.length ? (
                  <Box style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                    <PasswordCriterion
                      description="Must be minimum 8 characters"
                      rightmet={this.metMin8Characters()}
                      customStyle={{ wrapper: { marginBottom: 4 } }}
                      isFalseIcon
                    />
                    <PasswordCriterion
                      description="Must contain at least one number"
                      rightmet={this.metAtLeastOneNumber()}
                      customStyle={{ wrapper: { marginBottom: 4 } }}
                      isFalseIcon
                    />
                    <PasswordCriterion
                      description="Must contain at least 1 special character"
                      rightmet={this.metAtLeastOneSpecialCharacter()}
                      customStyle={{ wrapper: { marginBottom: 4 } }}
                      isFalseIcon
                    />
                  </Box>
                ) : null}
                <Typography
                  variant="body1"
                  className="fontWeight600 colorGrey marginTop10"
                >
                  {configJSON.confirmPasswordText}
                </Typography>
                <Field
                  data-test-id="password_confirmation"
                  name="password_confirmation"
                  as={CustomInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("password_confirmation", event.target.value);
                    this.setState((prevState) => {
                      return {
                        passwordInputs: {
                          ...prevState.passwordInputs,
                          password_confirmation: event.target.value,
                        },
                      };
                    });
                  }}
                  value={values.password_confirmation}
                  error={Boolean(
                    touched.password_confirmation &&
                      errors.password_confirmation
                  )}
                  errorMsg={errors.password_confirmation}
                  isVisibilityIcon
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={`${
                isValid &&
                dirty &&
                this.metAtLeastOneNumber() &&
                this.metAtLeastOneSpecialCharacter() &&
                this.metMin8Characters()
                  ? ""
                  : "disabledBtn"
              }`}
              disabled={
                !(
                  isValid &&
                  dirty &&
                  this.metAtLeastOneNumber() &&
                  this.metAtLeastOneSpecialCharacter() &&
                  this.metMin8Characters()
                )
              }
            >
              {configJSON.updatePasswordBtnText}
            </Button>
          </Form>
        )}
      </Formik>
    );
  };
  // Customizable Area End
}
