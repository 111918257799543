import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme,
} from "@material-ui/core";
import React, { Component } from "react";
import CustomInput from "./CustomInput.web";
import ShareRoundedIcon from "@material-ui/icons/ShareRounded";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import CloseIcon from "@material-ui/icons/Close";

const assets = require("./assets");

interface Props {
  isOpen: boolean;
  link: string;
  onOpenCloseModal: () => void;
  handleCopyText: (value: string) => void;
}

interface S {
  copyText: boolean;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
    },
    secondary: {
      main: "#059669",
    },
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: 700,
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        color: "#0F172A",
      },
      body1: {
        fontSize: "1rem",
        fontFamily: "Outfit",
        fontWeight: 700,
      },
      body2: {
        fontSize: "0.9rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#0F172A",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "8px",
      },
      adornedEnd: {
        paddingRight: 0,
        borderRadius: "8px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        fontFamily: "Outfit",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "capitalize",
        margin: 0,
      },
      containedPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#0F172A",
        opacity: "0.4 !important",
      },
    },
    MuiDialogContent: {
      root: {
        boxSizing: "border-box",
        padding: "32px 32px",
        "&:first-child": {
          paddingTop: 34,
        },
        background: "#FFF",
        boxShadow:
          "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
        borderRadius: "32px",
      },
    },
    MuiPaper: {
      root: {
        borderRadius: "32px !important",
      },
    },
  },
});

export default class ShareModal extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      copyText: false,
    };
  }

  render() {
    const {
      isOpen = false,
      link = "",
      onOpenCloseModal,
      handleCopyText,
    } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Dialog
          fullWidth
          open={isOpen}
          onClose={() => {
            onOpenCloseModal();
            this.setState({ copyText: false });
          }}
        >
          <DialogContent>
            <IconButton
              style={{
                position: "absolute",
                right: 12,
                top: 22,
                color: "#0F172A",
              }}
              aria-label="close"
              onClick={() => {
                onOpenCloseModal();
                this.setState({ copyText: false });
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              data-test-id="shareModalTitle"
              variant="h6"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <ShareRoundedIcon style={{ marginTop: "-5px" }} /> Share
              reservation
            </Typography>
            <Typography variant="body2" style={{ marginTop: "15px" }}>
              Reservation link
            </Typography>
            <CustomInput
              size="small"
              value={link}
              startIcon={LinkRoundedIcon}
              InputProps={{
                endAdornment: (
                  <Button
                    variant="contained"
                    color={this.state.copyText ? "secondary" : "primary"}
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      handleCopyText(link);
                      this.setState({ copyText: true });
                    }}
                  >
                    {this.state.copyText ? "Copied" : "Copy"}
                  </Button>
                ),
              }}
            />
            <Divider style={{ margin: "20px 0" }} />
            <Typography
              variant="body1"
              style={{ fontWeight: 500, color: "#000000" }}
            >
              Share with others
            </Typography>
            <Box style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              <MailOutlineRoundedIcon
                data-test-id="mailIcon"
                style={{ width: "24px", height: "24px" }}
                onClick={() => {
                  window.open(
                    `mailto:?subject=Reservation Link&body=Check out this link: ${link}`
                  );
                }}
              />
              <img
                data-test-id="facebookIcon"
                src={assets.facebookIcon}
                style={{ width: "24px", height: "24px" }}
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${link}`,
                    "_blank"
                  );
                }}
                loading="lazy"
              />
              <img
                data-test-id="whatsappIcon"
                src={assets.whatsappIcon}
                style={{ width: "24px", height: "24px" }}
                onClick={() => {
                  window.open(
                    `https://wa.me/?text=${encodeURIComponent(link)}`,
                    "_blank"
                  );
                }}
                loading="lazy"
              />
              <img
                src={assets.instagramIcon}
                style={{ width: "24px", height: "24px" }}
                loading="lazy"
              />
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }
}
