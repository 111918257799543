Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.apiFormContentType = "multipart/form-data";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";

exports.getChatListApiEndPoint = "chat/chats";
exports.showChatApiEndPoint = "chat/chats";
exports.getChatHistoryApiEndPoint = "chat/chats/history";
exports.searchMessageApiEndPoint = "chat/chats/search_messages";
exports.searchChatGroupApiEndPoint = "chat/chats/search";
exports.createChatRoomApiEndPoint = "chat/chats";
exports.addUserToChatApiEndPoint = "chat/chats/add_user";
exports.leaveChatApiEndPoint = "chat/chats/leave";
exports.sendMessageApiEndPoint = "chat/chats";
exports.updateReadMessageApiEndPoint = "chat/chats/read_messages";
exports.updateChatApiEndPoint = "chat/chats";

exports.getMyChatsApiEndpoint = "bx_block_chat/chats";
exports.createChatRoomWebApiEndPoint = "chat/web_chats";
exports.messagesApiEndPoint = "bx_block_chat/messages";

exports.createButtonText = "Create";
exports.createRoomButtonText = "Create Room";
exports.addButtonText = "Add";
exports.closeButtonText = "Close";
exports.addAccountText = "Add Account";
exports.leaveChatRoomText = "Leave Chat";
exports.sendText = "Send";
exports.cancelText = "Cancel";
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.dayDockerLogoText = "DayDocker Logo";
exports.inboxTitleText = "Inbox";
exports.searchPlaceholder = "Search for names, listings here";
exports.detailsTitle = "Details";
exports.addressTitle = "Address";
exports.reservationDetailsTitle = "Reservation Details";
exports.checkIn = "CHECK-IN";
exports.checkOut = "CHECK-OUT";
exports.guestText = "GUESTS";
exports.cancellationPolicyText = "Cancellation Policy";
exports.noNewMessagesText = "No new messages!";
exports.goToListings = "Go to your listing";
exports.dummyText = "dummy text";
// Customizable Area End