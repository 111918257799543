import React from "react";
// Customizable Area Start
import { Container, Box, Typography, Chip } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import { theme } from "../../../blocks/chat/src/Chat.web";
import ReservationTable from "./ReservationTable.web";
import NoDataFound from "../../../components/src/NoDataFound.web";
export const assets = require("./assets");

export const boaterReservationTheme = createTheme({
  overrides: {
    MuiTypography: {
      subtitle1: {
        fontFamily: "Outfit",
        color: "#64748B",
        fontWeight: 400,
        fontSize: "1rem",
      },
      root: {
        fontFamily: "Josefin Sans",
      },
      h4: {
        fontFamily: "Josefin Sans",
        fontSize: "1.5rem",
        fontWeight: 700,
      },
    },
    ...theme,
  },
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    },
  },
});

import BoaterReservationListController, {
  Props,
  configJSON,
} from "./BoaterReservationListController.web";

// Customizable Area End

export default class BoaterReservationList extends BoaterReservationListController {
    constructor(props: Props) {
        // Customizable Area Start
    super(props);
        // Customizable Area End
    }
    // Customizable Area Start
  renderBoaterReservationTable = () => {
    if (
      this.state.selectedTab === configJSON.boaterDockingText &&
      this.state.activeReservationListData.length
    ) {
      return (
        <>
          <Typography
            variant="subtitle1"
            className="coloredSubtitle fontWeight600 padding30"
          >
            {configJSON.boaterDockingText}
          </Typography>
          <ReservationTable
            listData={this.state.activeReservationListData}
            headerList={this.state.reservationHeaderData}
            testId={this.state.selectedTab}
            actions={["Status"]}
            active={['Status']}
            totalCount={this.state.total_count}
            totalPages={this.state.total_pages}
            isKycDisplayed={true}
            redirectToDetails={true}
            pageChange={(page: number) => this.fetchReservationsList(page)}
            navigation={this.props.navigation}
          />
        </>
      );
    } else if (
      this.state.selectedTab === configJSON.boaterDockedText &&
      this.state.pastReservationList.length
    ) {
      return (
        <>
          <Typography
            variant="subtitle1"
            className="coloredSubtitle fontWeight600 padding30"
          >
            {configJSON.boaterDockedText}
          </Typography>
          <ReservationTable
            testId={this.state.selectedTab}
            listData={this.state.pastReservationList}
            headerList={this.state.reservationHeaderData}
            totalCount={this.state.total_count}
            totalPages={this.state.total_pages}
            pageChange={(page: number) => this.fetchReservationsList(page)}
          />
        </>
      );
    } else {
      return (
        <NoDataFound
          name="No reservations"
          image={assets.noReservationIcon}
          redirectedMsg={configJSON.exploreDaydocker}
          message={configJSON.noReservationsText}
          navigation={this.props.navigation}
          redirectionRoute={"LandingPage"}
        />
      );
    }
  };
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
      <ThemeProvider theme={boaterReservationTheme}>
        <Wrapper data-test-id="mainWrapper">
          <Container maxWidth="lg">
            <Header
              navigation={this.props.navigation}
              isLoggedIn={true}
              role="boater"
              data-testId="headerText"
            />
            <Box className="inboxHeader">
              <Typography
                variant="h4"
                data-test-id="titleText"
                className="headerTitle padding20"
              >
                {configJSON.myReservationsTitleText}
              </Typography>
            </Box>
            <Box className="bodyContent">
              <Box className="flexItems">
                {[
                  configJSON.boaterDockingText,
                  configJSON.boaterDockedText,
                ].map((chipItem) => (
                  <Chip
                    key={chipItem}
                    variant="outlined"
                    label={
                      <Typography
                        variant="subtitle1"
                        className={`${chipItem === this.state.selectedTab ? "chipTextSelected" : ""
                          }`}
                      >
                        {chipItem}
                      </Typography>
                    }
                    data-test-id="tableViewSelector"
                    className={`chipContent ${chipItem === this.state.selectedTab ? "selectedChip" : ""
                      }`}
                    onClick={() => this.handleListTabView(chipItem)}
                  />
                ))}
              </Box>
              <Box>
                {this.renderBoaterReservationTable()}
              </Box>
            </Box>
          </Container>
        </Wrapper>
      </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .padding30": {
    padding: "30px 0 20px 0",
  },
  "& .fontWeight600": {
    fontWeight: 600,
  },
  "& .padding20": {
    padding: "20px 0 10px 0",
  },
  "& .flexItems": {
    display: "flex",
    flexWrap: "wrap",
    gap: "7px",
  },
  "& .coloredSubtitle": {
    color: "#0F172A",
  },
  "& .chipTexts": {    
    color: "#CBD5E1", fontSize: "16px", fontWeight: 400, fontFamily: 'Outfit'
  },
  "& .bodyContent": {
    padding: "10px 0",
  },
  "& .chipContent": {
    borderColor: "#CBD5E1",
    borderRadius: "26px",
    padding: "18px 6px",
    height: "44px", textTransform: "none"
  },
  "& .chipTextSelected": {
    color: "white",
    fontWeight: 700,
    fontSize: "16px", fontFamily: 'Outfit'
  }, 
  "& .selectedChip": {
    backgroundColor: "#4F9FF8 !important",
  },
  "& .inboxHeader": {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  }, 
  [theme.breakpoints.down(600)]: {    
    "& .headerTitle": {
      fontSize: "1.2rem",
    },
    "& .inboxHeader": {
      paddingTop: "10px",
    },
  },
  [theme.breakpoints.down(840)]: {
    "& .headerTitle": {
      fontSize: "1.3rem",
    },
  },
}));
// Customizable Area End
