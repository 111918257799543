import React from "react";

// Customizable Area Start
import {
  styled,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import GenericModal from "../../../components/src/GenericModal";
import Toast from "../../../components/src/Toast";
import PasswordCriterion from "../../../components/src/PasswordCriterion";
const config = require("./config");
const assets = require("./assets");
// Customizable Area End

import ForgotModalController, {
  Props, 
  // Customizable Area Start
  Step,
  // Customizable Area End
} from "./ForgotModalController";

// Customizable Area Start
const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Outfit, sans-serif"
      },
      body1: {
        fontFamily: "Outfit, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Outfit, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Outfit, sans-serif"
      }
    }
  }
})

const MainWrapper = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  margin: '0',
  padding: '0',
  width: '100%',
  color: '#0F172A',
  [theme.breakpoints.down('md')]: {},
  '& input.error': {
    borderColor: '#F87171',
  },
  '& .link': {
    background: 'none',
  },
  '& .blue-link': {
    color: '#4F9FF8',
    fontSize: 16,
    fontWeight: 700,
  },
  '& .btn': {
    fontFamily: '"Outfit", sans-serif',
    border: 0,
    cursor: 'pointer',
  },
  '& .title': {
    color: '#0F172A',
    fontFamily: '"Josefin Sans", sans-serif',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.12px',
    marginBottom: 0,
  },
  '& .sub-title': {
    fontFamily: '"Outfit", sans-serif',
    color: '#64748B',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    marginBottom: 16,
  },
  '& .brandname': {
    color: '#4F9FF8',
  },
  '& .field-wrapper': {
    marginBottom: 16,
  },
  '& .field-label': {
    fontFamily: 'Outfit',
    display: 'block',
    color: '#334155',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '22px',
    marginBottom: 4,
  },
  '& .field-value': {
    display: 'block',
    width: '100%',
    padding: '10px 8px',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    background: '#FFF',
    color: '#0F172A',
    fontFamily: 'Outfit',
    fontSize: 16,
    fontWeight: 400,
    '&::placeholder': {
      color: '#94A3B8',
    }
  },
  '& .forgot-btn': {
    width: '100%',
    padding: 16,
    background: '#4F9FF8',
    color: '#FFF',
    borderRadius: '8px',
    fontSize: 16,
    fontWeight: 700,
    '&:disabled': {
      color: '#64748B',
      background: '#F1F5F9',
      cursor: 'not-allowed',
    },
  },
  '& .error-msg': {
    fontSize: 12,
    color: '#DC2626',
    fontWeight: 400,
    lineHeight: '18px',
    marginTop: 2,
  },
  '& .password-wrapper': {
    position: 'relative',
  },
  '& .toggle-pw-visibility': {
    position: 'absolute',
    zIndex: 100,
    top: '50%',
    right: 8,
    transform: 'translateY(-50%)',
    backgroundImage: `url(${assets.wannaSee})`,
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  '& .password-criteria': {
    marginBottom: 32,
  },
}));
// Customizable Area End

export default class ForgotModal extends ForgotModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEnterEmailStep() {
    const { enteredEmail, emailError } = this.state;

    return (
      <>
        <div className="field-wrapper">
          <label className="field-label" htmlFor="email">Email address</label>
          <input
            required
            type="text"
            id="email"
            data-test="input-email"
            value={enteredEmail}
            name="enteredEmail"
            onChange={this.handleOnChange}
            className={`field-value ${emailError ? 'error' : ''}`}
            placeholder="eg. email@address.com"
          />
          <div data-test="email-error" className="error-msg">{emailError}</div>
        </div>
      </>
    );
  }

  renderVerifyEmailStep() {
    const { enteredVerifyCode } = this.state;

    return (
      <>
        <div className="field-wrapper" style={{ marginBottom: 32 }}>
          <label className="field-label" htmlFor="verify-code">Enter code</label>
          <input
            data-test="input-verify-code"
            id="verify-code"
            name="enteredVerifyCode"
            type="text"
            className="field-value"
            style={{ textAlign: 'center' }}
            value={enteredVerifyCode}
            placeholder="123456"
            onChange={this.handleOnChange}
          />
        </div>
        <div style={{ fontSize: 14 }}>
          <span>Didn't receive the code?</span>
          <button
            data-test="resend-verify-code"
            type="button"
            style={{ fontSize: 14 }}
            onClick={this.resendVerifyCode}
            className="btn link blue-link"
          >Resend</button>
        </div>
      </>
    );
  }

  renderEnterPasswordStep() {
    const {
      enteredPassword,
      enteredConfirmPassword,
      isPasswordObscured,
      confirmPasswordError,
    } = this.state;

    return (
      <>
        <div className="field-wrapper">
          <label className="field-label" htmlFor="password">New Password</label>
          <div className="password-wrapper">
            <input
              data-test="input-password"
              name="enteredPassword"
              id="password"
              className="field-value"
              type={isPasswordObscured ? 'password' : 'text'}
              value={enteredPassword}
              placeholder="************"
              onChange={this.handleOnChange}
              required
            />
            <div
              className="toggle-pw-visibility"
              style={{ backgroundImage: `url(${isPasswordObscured ? assets.wannaSee : assets.notWannaSee})` }}
              onClick={this.togglePasswordVisibility}
            ></div>
          </div>
        </div>

        <div className="field-wrapper">
          <label htmlFor="confirm-password" className="field-label">Confirm Password</label>
          <div className="password-wrapper">
            <input
              id="confirm-password"
              data-test="input-confirm-password"
              name="enteredConfirmPassword"
              className={`field-value ${confirmPasswordError ? 'error' : ''}`}
              type={isPasswordObscured ? 'password' : 'text'}
              value={enteredConfirmPassword}
              placeholder="************"
              onChange={this.handleOnChange}
              required
            />
            <div
              data-test="toggle-password-visibility"
              className="toggle-pw-visibility"
              style={{ backgroundImage: `url(${isPasswordObscured ? assets.wannaSee : assets.notWannaSee})` }}
              onClick={this.togglePasswordVisibility}
            ></div>
          </div>
          <div data-test="confirm-password-error" className="error-msg">{confirmPasswordError}</div>
        </div>

        <div className="password-criteria">
          <PasswordCriterion description="Min. 8 characters" met={this.metMin8Characters} customStyle={{ wrapper: { marginBottom: 4 } }} />
          <PasswordCriterion description="1 number" met={this.metAtLeastOneNumber} customStyle={{ wrapper: { marginBottom: 4 } }} />
          <PasswordCriterion description="1 special character" met={this.metAtLeastOneSpecialCharacter} customStyle={{ wrapper: { marginBottom: 4 } }} />
        </div>
      </>
    );
  }

  renderFormContent() {
    const { currentStep } = this.state;

    switch (currentStep) {
      case Step.EnterEmail:
        return this.renderEnterEmailStep();
      case Step.VerifyEmail:
        return this.renderVerifyEmailStep();
      case Step.EnterPassword:
        return this.renderEnterPasswordStep();
      default:
        return null;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { errorMessage, isLoading, currentStep, successMessage } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <GenericModal dataTest="forgot-modal" open={this.props.isOpen} onClose={this.props.onClose}>
          <MainWrapper data-test-id="mainWrapper">
            <div
              className="title"
              data-test="title"
              dangerouslySetInnerHTML={{ __html: this.title }}
            />
            <div
              className="sub-title"
              dangerouslySetInnerHTML={{ __html: this.subTitle }}
            />
            {errorMessage && <Toast data-test="toast" message={errorMessage} />}
            {successMessage && <Toast data-test="toast" message={successMessage} type="success" />}
            {currentStep !== Step.UpdatedSuccessfully && 
            (
              <form
                onSubmit={this.handleSubmit}
                data-test="forgot-form"
                style={{ margin: 0 }}
              >
                {this.renderFormContent()}

                <button
                  style={{ marginTop: 40 }}
                  disabled={this.isDisabled || isLoading}
                  data-test="forgot-btn"
                  className="btn forgot-btn"
                >
                  {this.submitBtnText}
                </button>
              </form>
            )}
            {currentStep === Step.UpdatedSuccessfully && (
              <div style={{ textAlign: 'center', marginTop: 40, }}>
                <button
                  type="button"
                  data-test="go-to-login"
                  onClick={this.goToLogin}
                  className="btn link blue-link">Go to Login</button>
              </div>
            )}
          </MainWrapper>
        </GenericModal>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End