// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { ArrowBackIosRounded } from "@material-ui/icons";
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Toast from "../../../components/src/Toast";

const BpIcon1 = styled('span')(() => ({
  borderRadius: "6px",
  width: 20,
  height: 20,
  marginLeft:"-9px",
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  'input:disabled ~ &': {
      boxShadow: 'none',
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
},
}));

const BpCheckedIcon1 = styled(BpIcon1)({
  backgroundColor: '#4F9FF8',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    width: 20,
    display: 'block',
    height: 20,
      backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#106ba3',
  },
});

const BpCheckbox1 = (props: CheckboxProps) => {
  return (
      <Checkbox
      checkedIcon={<BpCheckedIcon1 />}
      icon={<BpIcon1 />}
      color="default"
      disableRipple
          inputProps={{ 'aria-label': 'list-data' }}
          {...props}
      />
  );
};

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans !important",
      },
      body1: {
        lineHeight: "32px",
        fontWeight: 400,
        fontSize: "24px",
      },
    },
  }
});

import JobListingController, {
  Props,
  configJSON,
} from "./JobListingController.web";

class TermsAndConditions extends JobListingController {
  constructor(props: Props) {
    super(props);
  }
  static TermsAndConditions: TermsAndConditions;
  render() {
    const termsOfService = this.state.termsConditionList;
    return (
      <>
        <ThemeProvider theme={theme}>
          <MainWrapper data-test-id="mainWrapper">
            <Container maxWidth="lg">
              <Header navigation={this.props.navigation} />
              <Box style={{ marginBottom: '100px' }}>
                <div className="iconPlace" style={{ flexDirection: 'row' }}>
                  <ArrowBackIosRounded data-test-id='backBtn' style={{ cursor: 'pointer' }} onClick={() => this.goBack()} />
                  <Typography
                    className="titleText"
                    data-test-id="Frequently"
                  >
                    {configJSON.titleTermsService}
                  </Typography>
                </div>  
                <Box style={{ minHeight: "26vh" }}>             
                {!this.state.nodata ? (<>
                <Box>
                      {termsOfService.terms && <>
                        <Typography className="allText1">1. Terms</Typography>
                        <Typography className="allText">
                          <Box dangerouslySetInnerHTML={{__html: termsOfService.terms}}></Box>
                        </Typography>
                      </> } 
                      {termsOfService.license && <>
                        <Typography className="allText1">2. Use License</Typography>
                        <Typography className="allText">
                          <Box dangerouslySetInnerHTML={{__html: termsOfService.license}}></Box>
                        </Typography>
                      </> } 
                      {termsOfService.disclaimer && <>
                        <Typography className="allText1">3. Disclaimer</Typography>
                        <Typography className="allText">
                          <Box dangerouslySetInnerHTML={{__html: termsOfService.disclaimer}}></Box>
                        </Typography>
                      </> } 
                      {termsOfService.limitations && <>
                        <Typography className="allText1">4. Limitations</Typography>
                        <Typography className="allText">
                          <Box dangerouslySetInnerHTML={{__html: termsOfService.limitations}}></Box>
                        </Typography>
                      </> } 
                      {termsOfService.revision_and_errata && <>
                        <Typography className="allText1">5. Revisions and Errata</Typography>
                        <Typography className="allText">
                          <Box dangerouslySetInnerHTML={{__html: termsOfService.revision_and_errata}}></Box>
                        </Typography>
                      </> } 
                </Box>
                <Box>
                  <ListItem style={{ margin: '10px 0 0 10px',padding: "0 40px 0 0", }} >
                    <ListItemIcon style={{ minWidth: 30 }}>
                      <BpCheckbox1 checked={termsOfService.active} onChange={this.handleChange} inputProps={{'aria-label':'checkbox'}}/>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography style={{marginLeft:'0px' }} className="allText" >
                          By selecting <span style={{color: "#0F172A" ,fontWeight: 700,}}>Agree and continue</span>, I agree to the Daydocker Terms of Service and acknowledge the Privacy Policy.
                        </Typography>
                      }
                    />
                  </ListItem>
                  {this.state.updateErrorMsg && (
                    <Toast data-test-id="errorMsg" message={this.state.updateErrorMsg} />
                  )}
                  {this.state.updateSuccessMsg && (
                    <Toast data-test-id="updateSuccessMsg" message={this.state.updateSuccessMsg} type="success"/>
                  )}
                </Box>
                </>) : (
                  <Typography className="title2">No Terms and Conditions found</Typography>
                )
                }
                </Box> 
              </Box>
            </Container>
            <Footer navigation={this.props.navigation} />
          </MainWrapper>
        </ThemeProvider>
      </>
    );
  }
}

export default TermsAndConditions;

const MainWrapper = styled('div')(({ theme }) => ({ 
  "& .title2": {
    fontFamily: 'Josefin Sans',
    fontSize: '24px',
    fontWeight: 700,
    marginTop: '47px',
    justifyContent: 'center',
    display: 'flex',
    color: '#94A3B8'
  }, 
  "& .titleText": {
    fontSize: "36px",
    fontWeight: "700",
    fontStyle: 'Josefin Sans',
    letterSpacing: "-0.01em",
    lineHeight: 1.1,
  },
  "& .iconPlace": {
    gap: '14px',
    display: 'flex',
    margin: "50px 0 20px 0",
    marginLeft: '-30px',
    alignItems: 'baseline',
    '@media (max-width: 1300px)': {
      marginLeft: '0px',
    },
  },
  "& .margin": {
    margin: '5px 0 5px 5px'
  },
  "& .allText": {
    fontFamily: "Outfit",fontSize: "18px", fontWeight: 400, color: "#64748B",lineHeight:'20.16px', marginLeft: '10px',
  }, 
  "& .allText1": {
    fontFamily: "Outfit",fontSize: "18px", fontWeight: 700, color: "#64748B",lineHeight:'20.16px', marginLeft: '10px',
  },
}));
// Customizable Area End