import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  Box,
  styled,
  Divider,
  Button,
  Radio,
} from "@material-ui/core";
import Header from "../../../components/src/Header";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import StripeCardForm from "../../../components/src/StripeCardForm.web";
import Toast from "../../../components/src/Toast";
import KnowYourCustomerKycVerificationAccount from "../../../blocks/knowyourcustomerkycverification/src/KnowYourCustomerKycVerificationAccount.web";

const assets = require("./assets");
const configJSON = require("./config.js");
// Customizable Area End

import DockBookingPaymentController, {
  Props,
} from "./DockBookingPaymentController.web";
import { getCardIcon } from "../../../components/src/utils";

export default class DockBookingPayment extends DockBookingPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { onBackBtnClick, navigation, kycModal, renderPaymentCard } = this.props;

    return (
      <>
        <KnowYourCustomerKycVerificationAccount
          navigation={navigation}
          isModalOpen={kycModal}
          kycRedirectUrl={this.state.kycRedirectUrl}
        />
      <Wrapper data-test-id="mainWrapper">
        <Header navigation={navigation} />
        <Button
          className="backBtn"
          startIcon={<ArrowBackIosRoundedIcon />}
          onClick={onBackBtnClick}
          data-test-id="backBtn"
        >
          {configJSON.backBtnText}
        </Button>
        <Box className="headerTitle">
          <Typography
            variant="h1"
            className="fontWeight700"
            data-test-id="titleText"
          >
            {configJSON.paymentDetailsText}
          </Typography>
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              color: "rgb(221, 221, 90)",
            }}
          >
            <img
              src={assets.timeIcon}
              style={{ width: "25px", height: "auto" }}
              loading="lazy"
            />
            {this.props.timer}
          </Typography>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            {this.state.savedCardList.length > 0 && (
              <>
                <Typography variant="body1" className="fontWeight700">
                  {configJSON.savedCardText}
                </Typography>
                {this.state.savedCardList.map((cardItem) => (
                  <Box key={cardItem.id} className="cardBox flexItems">
                    <Radio
                      data-test-id="savedCardSelect"
                      className="radioBtn"
                      checked={Boolean(
                        this.state.selectedCard &&
                          this.state.selectedCard.id === cardItem.id
                      )}
                      disableRipple
                      color="primary"
                      onClick={() => this.handleSelectedCard(cardItem)}
                    />
                    <img
                      src={getCardIcon(cardItem.type)}
                      alt={cardItem.type}
                      className="cardLogo"
                      loading="lazy"
                    />
                    <Box className="flexCol">
                      <Typography
                        variant="body1"
                        className="fontWeight700"
                        style={{ textTransform: "capitalize" }}
                      >
                        {cardItem.type} ****{cardItem.last4}
                      </Typography>
                      <Typography variant="subtitle1">
                        Expiration date - {cardItem.expiry_date}
                      </Typography>
                    </Box>
                  </Box>
                ))}
                <Divider />
              </>
            )}
            <Typography variant="body1" className="fontWeight700">
              {configJSON.paywithText}
            </Typography>
            <Box className="addCardBox">
              <Radio
                data-test-id="newCardSelect"
                className="radioBtn"
                checked={this.state.selectedCard === null}
                disableRipple
                color="primary"
                onClick={() => this.handleSelectedCard(null)}
              />
              <Box className="flexCol">
                <Typography
                  variant="body1"
                  className="fontWeight700 creditCardTitle"
                >
                  Credit card or debit card
                </Typography>
                {this.state.selectedCard === null && (
                  <StripeCardForm
                    actionBtnRequired={false}
                    handleResponse={this.storeCardDetails}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            {this.state.errorMsg && <Toast message={this.state.errorMsg} />}
            {renderPaymentCard(this.handleBookDockBtnClick)}
          </Grid>
        </Grid>
      </Wrapper>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .fontWeight700": {
    fontWeight: "700 !important",
  },
  "& .colorGrey": {
    fontSize: "14px !important",
    color: "#334155",
    fontWeight: 600,
  },
  "& .marginTop0": {
    marginTop: 0,
  },
  "& .marginTop10": {
    marginTop: "10px",
  },
  "& .marginBottom10": {
    margin: "15px 0 10px",
  },
  "& .flexItems": {
    display: "flex",
    gap: 10,
  },
  "& .justifySpaceBetween": {
    justifyContent: "space-between",
  },
  "& .colorDarkBlue": {
    color: "#0F172A",
  },
  "& .flexGap": {
    display: "flex",
    gap: "15px",
    flexDirection: "column",
  },
  "& .guestTotalFlex": {
    display: "flex",
    flexDirection: "column",
  },
  "& .headerTitle": {
    margin: "15px 0 20px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .backBtn": {
    fontSize: "18px",
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#64748B",
    textTransform: "none",
  },
  "& .cardBox": {
    marginTop: "20px",
    padding: "15px 10px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    alignItems: "center",
  },
  "& .flexCol": {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  "& .cardLogo": {
    width: "24px",
    height: "24px",
  },
  "& .addCardBox": {
    marginTop: "20px",
    padding: "15px 50px 15px 10px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    display: "flex",
    alignItems: "flex-start",
  },
  "& .radioBtn": {
    padding: "5px 10px",
  },
  "& .creditCardTitle": {
    margin: "4px 0",
  },
  "& .borderedBox": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "30px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  [theme.breakpoints.down(500)]: {
    "& .addCardBox": {
      padding: "15px 10px",
    },
  },
}));
// Customizable Area End
